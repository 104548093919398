.storeItemCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 204px;
  height: 248px;
  border-radius: 16px;
  background: #ffffff80;
  text-align: center;
  margin: 10px;
  position: relative;
}

.storeItemCardText {
  display: flex;
  align-items: center;
}

.storeItemCardIcon {
  width: 100%;
  height: 90px;
}


.storeItemCardEnableRedBoarder {
  border: 2px solid rgba(252, 46, 18, 0.8);
  position: relative;
  box-sizing: border-box;
}

.storeItemCardAddOn {
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 768px) {
  .storeItemCard {
    width: 100px;
    height: 159px;
    justify-content: space-around;
  }

  .storeItemIcon {
    width: 90%;
  }

}

@media screen and (max-width: 768px) {
  .storeItemCard {
    margin: 8px;
  }
}