.leaderboardHeadings {
  display: flex;
  align-items: center;
  margin-left: 150px;
  font-weight: bold;
}
.trophy {
  margin-left: -30px;
  padding-right: 25px;
}
.lines > :first-child span {
  border: 2px solid #ffce0d;
}

.lines > :nth-child(2) span {
  border: 2px solid #ffbc03;
}

.lines > :first {
  color: #ffd700;
}
.grey {
  color: #9f9fa4;
}

.comingSoon {
  text-align: center;
  margin-top: 50px;
}

@media screen and (max-width: 650px) {
  .leaderboardHeadings {
    margin-left: 90px;
  }

  .trophy {
    margin-left: -30px;
    padding-right: 25px;
  }
}
