.storeBoosterCard {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 150px;
    height: 230px;
    border: 1px solid #bababa;
    border-radius:16px;
    background: #FFFFFF80;
    text-align: center;
    margin: 9px;
    box-sizing: border-box;

}

.storeBoosterCardText {
    display:flex;
    align-items: center;
    height:55px;
}

.storeBoosterCardIcon {
    width:65%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blueBackground {
    background: linear-gradient(180deg, #2E95FA 0%, #1872CA 100%);
}

.blueBackground > div {  /* to be removed */
    font-size: 19px;
}

@media screen and (max-width: 768px) {
    .storeBoosterCard {
        width: 100px;
        height: 150px;
        margin: 8px;
        justify-content: space-around;
    }

    .storeBoosterCardText {
        height:10px;
    }

    .storeBoosterCardIcon {
        width: 70%;
    
    }

    .multiplyNum {
        font-size: 14px;
    }
    
    .blueBackground { /* to be changed */
        height: 16%;
    }
    .blueBackground > div {  /* to be removed */
        font-size: 12px;
    }
  }