.spinning-wheel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
}

.spinning-wheel-container > div {
  position: absolute;
}
