.signupLoginPageContainer {
  height: 100vh;
  display: flex;
  /* margin-top: 100px;  on mobile? */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 650px) {
  .signupLoginPageContainer { 
    padding: 0;
    margin: 0;
    /* align-items: unset; */
  }
}
