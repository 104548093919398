.playButton {
  border: none;
  position: relative;
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
  height: 56px;
  width: 100%;
  border-radius: 16px;
}

.playButtonDisabled {
  border: none;
  position: relative;
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
  height: 56px;
  width: 100%;
  border-radius: 16px;
  /* Make button red */
  background: red;
}

.playButtonSuccess {
  border: none;
  position: relative;
  height: 56px;
  width: 100%;
  border-radius: 16px;
  /* Make button red */
  background-color: #3edb44; /* Vibrant green color */
}

.playButtonCompleted {
  border: none;
  position: relative;
  height: 56px;
  width: 100%;
  border-radius: 16px;
  background: linear-gradient(180deg, #b8950c 0%, #bd8304 100%);
  opacity: 0.7;
  
}

@media screen and (max-width: 768px) {
  .playButton {
    height: 28px;
    border-radius: 8px;
  }
  .playButtonSuccess {
    height: 28px;
    border-radius: 8px;
  }
  .playButtonCompleted {
    height: 28px;
    border-radius: 8px;
  }

  .buttonText {
    font-size: 12px;
  }
}
