.images-container {
  width: fit-content;
  position: relative;
}

.einstein-image {
  position: absolute;
  right: 50%;
  transform: translate(50%, 12%);
  width: clamp(246px,44vw,286px);
}

@media (max-width: 650px) {
  .confeti-image {
    width: 360px;
  }

  .einstein-image {
    transform: translate(50%, -10%);
  }
}
