.header {
  z-index: 51;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  padding: 0 20px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #bababa;
  max-width: 1520px;
}
.logo {
  margin-left: 24px;
}

.buttonRed {
  width: 165px;
  height: 60px;

  border: none;
  border-radius: 14px;
  /*/ usefull for centering text-section /*/
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(130.79deg, #f55d5d 24.71%, #dd3131 86.92%);
  margin-right: 50px;
}

.buttonSmallRed {
  width: 95px;
  height: 60px;

  border: none;
  border-radius: 14px;
  /*/ usefull for centering text-section /*/
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(130.79deg, #f55d5d 24.71%, #dd3131 86.92%);
  margin-right: 40px;
}
