.skewed-play-button {
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
  border: none;
  border-radius: 7px 14px;
  transform: skew(-19deg);
  /*/ usefull for centering text-section /*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-content {
  transform: skew(19deg);
  /* so that the two lines have the same start point */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.play-free {
  width: 240px;
  height: 70px;
}

.play-now,
.deactivated {
  width: 340px;
  height: 70px;
  letter-spacing: -0.3px;
}

.play-now-small {
  width: 249px;
  height: 60px;
  letter-spacing: -0.3px;
}

.play-prize {
  background: none;
  background-color: rgba(18, 21, 28, 0.5);
  border: 1px solid var(--f-2-f-2-f-2, #f2f2f2);
  width: 310px;
  height: 70px;
  align-items: stretch;
  justify-content: center;
  /* backdrop-filter: blur(10px); */
}

.play-prize-small {
  background: none;
  background-color: rgba(18, 21, 28, 0.5);
  border: 1px solid var(--f-2-f-2-f-2, #f2f2f2);

  height: 60px;
  align-items: stretch;
  justify-content: center;
  /* backdrop-filter: blur(10px); */
}

.play-prize-shine {
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg, #a3a3a3 0%, #8e8e8e 30%, #707070 50%, #8e8e8e 70%, #a3a3a3 100%);
  border: 1px solid var(--f-2-f-2-f-2, #f2f2f2);
  height: 60px;
  align-items: stretch;
  justify-content: center;
  align-items: center;
}

@keyframes shine {
  0% {
    transform: translateX(-100%) rotate(25deg);
  }
  80% { /* Move quickly within the first 20% of the animation */
    transform: translateX(200%) rotate(25deg);
  }
  100% { /* Hold for the rest of the duration */
    transform: translateX(200%) rotate(25deg);
  }
}

.shiny-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: translateX(-100%) rotate(25deg);
  animation: shine 3s ease-in-out infinite; /* Total duration is 5s */
  opacity: 0.7;
}

.play-prize-small > .button-content {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.play-prize > .button-content {
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.play-prize > .button-content > div {
  height: 100%; /* Makes the image take full height of the container */
  display: flex;
  align-items: center;
}

.deactivated {
  pointer-events: none;
  background: linear-gradient(180deg, #e6b80d 0%, #e69505 100%);
}
