.entireContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 30px;
}

.participantsContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.participantsContainer2 {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.participantsContainer2 > :first-child span {
  border: 2px solid #ffce0d;
}

.entireContainerCompleted {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  max-height: 230px;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 0 5px;
  margin-top: 20px;
}

.participantsContainerCompleted {
  display: flex;
  flex-direction: column;
  width: clamp(220px, 26vw, 400px);
  gap: 10px;
  width: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 650px) {
  .entireContainerCompleted {
    max-height: 220px;
  }
}
