.wheel {
  background-image: conic-gradient(
    #8f53db 0 45deg,
    #ff9b3c 45deg 90deg,
    #fed724 90deg 135deg,
    #e94a49 135deg 180deg,
    #8f53db 180deg 225deg,
    #ff9b3c 225deg 270deg,
    #fed724 270deg 315deg,
    #e94a49 315deg
  );
}
