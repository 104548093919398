.tooltipWrapper {
  position: relative;
  display: inline-block;
}

.tooltipTrigger {
  display: inline-block;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  top: 50%;
  left: 100%; /* Updated to align to the right */
  transform: translateY(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 20px;
  white-space: nowrap;
  z-index: 1;
  opacity: 0.9;
  display: none;
}

.tooltipWrapper:hover .tooltip {
  display: block;
}
.under {
  top: 110%;
  left: -20%;
}
