.tournamentPanelsContainer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    overflow-x:auto;
    padding-top: 40px;
    height: 354px;
    margin-bottom: 1px;
}

.loadingSpinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

@media screen and (max-width: 768px) {
    .tournamentPanelsContainer {
      padding-top: 30px;
      height: 200px;
      margin-left: 15px;
    }

    .loadSoon {
      position: absolute;
      right: 2px;
      width: 100%;
      height: clamp(220px,40vw,1400px);
    }
}

@media screen and (max-width: 650px) {
  .loadSoon {
    height: clamp(220px,35vw,1400px);
  }
}

