.circleQuarter {
    width: 60px;
    height: 60px;
    border-radius: 0 14px 0 60px;
    display:flex;
    justify-content: center;
    align-items: center;
    position:relative;
  }

.circleText {
    position: absolute;
    top: 45%;
    left:55%;
    transform: translate(-50%, -50%) rotate(+45deg);
    font-size:12px;
    text-align: center;
}

@media screen and (max-width: 768px) {

  .circleQuarter {
    width: 42px;
    height: 48px;
    border-radius: 0 19px 0 60px;
  }

  .circleText > div {
    font-size:8px;
  }

}