.menuPanel {
  z-index: 50;
  position: fixed;
  top: 105px;
  display: flex;
  flex-direction: column;
  border: none;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #bababa;
  padding: 12px;
  gap: 100px;
  background-color: rgba(255, 255, 255, 0.5);
}

@media (max-height: 960px) {
  .menuPanel {
    gap: 70px;
    padding: 5px;
    }
}

@media (max-height: 750px) {
  .menuPanel {
    gap: 30px;
    }
}