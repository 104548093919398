.typingSectionContainer {
  outline: #bababa solid;
  display: flex;
  align-items: center;
  height: clamp(50px, calc(3.13vw + 20px), 70px);
  justify-content: space-around;
  background-color: #fdfeff;
}

.inputArea {
  width: clamp(270px, calc(100vw - 790px), 588px);
  height: clamp(35px, calc(1.8vw + 20px), 48px);
}
.inputArea input {
  background-color: rgba(0, 0, 0, 0.03);
  border: #bababa solid;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font-size: clamp(16px, 1.68vw, 20px);
  box-sizing: border-box;
}

.typingSectionContainer button {
  border: none;
  background: linear-gradient(180deg, #2e95fa 0%, #1872ca 100%);
  border-radius: 40px;
  width: clamp(65px, calc(100vw - 920px), 143px);
  height: clamp(35px, calc(1.8vw + 20px), 48px);
}

.typingSectionContainer button > div {
  font-size: clamp(16px, 1.68vw, 20px);
}

@media screen and (max-width: 768px){
  .typingSectionContainer {
    height: 70px;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
  }

  .inputArea {
    width: 100%;
    height: 48px;
  }

  .inputArea input {
    padding-right: clamp(95px, calc(700px + ((50.5vw - 317px) * 6.09)), 135px);
  }

  .typingSectionContainer button {
    position: absolute;
    right: 20px;
    width: clamp(80px, calc(700px + ((50vw - 320px) * 6.09)), 120px);
    height: 37px;
  }
}
