.profileButtonSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 430px;
  margin: 0 auto;
}

.profileButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 205px;
  height: 54px;
  border-radius: 16px;
  border: none;
  background: var(--blue, linear-gradient(180deg, #2e95fa 0%, #1872ca 100%));
}

@media screen and (max-width: 1050px) {
  .profileButtonSection {
    flex-direction: column;
    width: 90%;
  }
  .profileButton {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .profileButtonSection {
    align-content: center;
  }
  .profileButton {
    width: 100%;
  }
}
