.notification-button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  width: 100%;
  height: 69px;
  flex-shrink: 0;
  border-radius: 24px;
  letter-spacing: -0.3px;
  background: linear-gradient(180deg, #0075ff 0%, #005dca 100%);
}

@media (max-width: 650px) {
  .notification-button {
    height: 56px;
    border-radius: 16px;

  }

  .notification-button div {
    font-size: 16px;
    font-weight: 600;
  }
}

@keyframes shine {
  0% {
    transform: translateX(-100%) rotate(25deg);
  }
  20% { /* Move quickly within the first 20% of the animation */
    transform: translateX(200%) rotate(25deg);
  }
  100% { /* Hold for the rest of the duration */
    transform: translateX(200%) rotate(25deg);
  }
}

.shiny-button {
  position: relative;
  overflow: hidden;
}

.shiny-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: translateX(-100%) rotate(25deg);
  animation: shine 5s ease-in-out infinite; /* Total duration is 5s */
  opacity: 0.7;
}

