.languageMenu {
  position: absolute;
  top: 80%;
  display: flex;
  gap: 14px;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 16px;
  border-radius: 16px;
}

.listItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  background: none;
  margin: 0;
  padding: 0;
  border: none;
}
