.boostersContainer {
  display: flex;
  justify-content: space-between;
  gap: 25px;
}

@media screen and (max-width: 650px) {
  .boostersContainer {
    padding: 0;
    margin-top: 10px;
    margin-bottom: -25px;
  }
}
