.boostersContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 24px;

  align-self: center;
}
@media screen and (max-width: 650px) {
  .boostersContainer {
    padding: 0;
    margin: 0;
  }
}
