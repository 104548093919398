.leaderboardsTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 20px);
  gap: 20px;
  padding-top: 150px;
  margin: 0 auto;
  padding-bottom: 120px;
  min-width: 350px;
  padding-left: 45px;
  width: 100%;
  box-sizing: border-box;
}

.leaderboardTitle{
  margin-left: 50px;
}

.slideContainer{
  margin-left: 50px;
}

@media screen and (max-width: 768px) {
  .leaderboardsTemplate {
    margin: 0 auto;
    padding-left: 0;  
  }

  .leaderboardTitle,
  .slideContainer{
    margin: 0;
  }
}
