.friendsTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-top: 150px;
  width: clamp(360px, calc(893px - (1150px - 100vw) * 1.182), 893px);
  margin: 0 auto;
  padding-left: 100px;
}

.friendsAvatarContainer {
  display: flex;
  margin: 15px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  align-items: center;
  justify-content: center;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.icon {
  border: 3px solid #bababa;
  border-radius: 50%;
}

@media screen and (max-width: 768px){
  .friendsTemplate {
    margin-left: 0px;
    margin: 0 auto 150px;
    padding-left: 0px;
  }
}

@media screen and (max-width: 650px) {
  .friendsTemplate {
    padding-top: 120px;
  }
}
