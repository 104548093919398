.desktopBanner-container {
  display: flex;
  width: 100%;
  position: relative;
  margin-top: 100px;
  overflow: hidden;
  height: 100%;
}

.desktopBanner-container {
  -webkit-mask-image: linear-gradient(to bottom, black, black 70%, transparent 90%);
  mask-image: linear-gradient(to bottom, black, black 65%, transparent 90%);
}

/*! =========   WHITISH FILTER  ========= */
.desktopBanner-container:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(transparent, #e0e0e0);
}

.backgroundImageContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -100;
}

.backgroundImageContainer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 43.92%, rgba(255, 255, 255, 0.7) 100%);
  backdrop-filter: blur(2px);
}

.backgroundImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*! ==========  EINSTEIN IMAGE  =========== */
.einsteinImageContainer {
  position: absolute;
  right: 0;
}

.einsteinImageContainer img {
  width: clamp(11em, 35vw, 28.5em);
}

.textAndButtonsContainer {
  padding-left: 120px;
  padding-top: 37px;
  margin-bottom: 50px;
  min-width: 185px;
  width: 69%;
}

.textAndButtonsContainer.loggedOut {
  padding-left: 15px;
  padding-top: 30px;
  width: 75%;
}

/*? ======================= HEADING - CAPTION ===================== */
/*! =========   HEADER TEXT   ========= */
.textAndButtonsContainer > div:nth-of-type(1) {
  font-size: clamp(32px, 4.8vw, 60px);
}

/*! =========   CAPTION TEXT   ========= */
.textAndButtonsContainer > div:nth-of-type(2) > div:nth-of-type(1) {
  max-width: 710px;
  width: 70%;
  font-size: clamp(14px, 1.73vw, 22px);
  padding-bottom: clamp(70px, 12vw, 100px);
  margin-top: clamp(10px, calc(18px - 12.2 * (525px - 100vw) / 15), 18px);
}
/*? ========== END ========== HEADING - CAPTION ===================== */

/* =========================== RESPONSIVE =========================== */

@media screen and (max-width: 1500px) {
  /*! =========   HEADER TEXT   =========*/
  .textAndButtonsContainer {
    width: 59%;
  }
}

@media screen and (max-width: 768px) {
  /*! =========   HEADER TEXT   =========*/
  .textAndButtonsContainer,
  .textAndButtonsContainer.loggedOut {
    padding-left: 15px;
    padding-top: 30px;
    width: 66%;
  }
}

@media screen and (max-width: 600px) {
  /*! =========   HEADER TEXT   =========*/
  .textAndButtonsContainer > div:nth-of-type(2) > div:nth-of-type(1) {
    width: 70vw;
  }
}

/*? =====================    406 PX    ======================= */
@media screen and (max-width: 510px) {
  /*! =========   CAPTION TEXT   ========= */
  .textAndButtonsContainer > div:nth-of-type(2) > div:nth-of-type(1) {
    width: 90vw;
    min-width: 350px;
  }

  /*! =========   HEADER TEXT   ========= */
  .textAndButtonsContainer > div:nth-of-type(1) {
    min-height: 130px;
  }

  .textAndButtonsContainer,
  .textAndButtonsContainer.loggedOut {
    width: 56.5%;
  }
}
/* ============= END =========== RESPONSIVE =========================== */
