.BackButton {
  top: 10px;
  left: 10px; /* Move it to the left side */
  font-size: 18px;
  font-weight: bold;
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.inputField {
  width: 300px; /* Adjust width to your preference */
  margin: 10px 0; /* Adds vertical spacing */
  padding: 8px; /* Adds padding inside the input */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.addButton {
  margin-top: 20px; /* Adds spacing above the button */
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.addButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error {
  color: red;
  font-size: 24px;
}

.SpecialQuizManagement {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}
