/*!  ==============   page container  ================  */
.spin-wheel-template {
  padding: 45px 0;

  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 80px;
}

/*!  ==============   caption  ================  */
.spin-wheel-template > div:nth-child(2) {
  margin-top: 20px;
  text-align: center;
  padding: 0 50px;
}

/*!  ==============   wheel  ================  */
.spin-wheel-template > div:nth-child(3) {
  margin: 32px 0 70px;
}

/*+ =================    SMALL SCREENS   ================== */
@media (max-width: 650px) {
  /*!  ==============   page container  ================  */
  .spin-wheel-template {
    padding: 20px 0;
  }

  /*!  ==============   header  ================  */
  .spin-wheel-template > div:nth-child(1) {
    font-size: 32px;
  }

  /*!  ==============   caption  ================  */
  .spin-wheel-template > div:nth-child(2) {
    margin-top: 5px;
  }

  /*!  ==============   wheel  ================  */
  .spin-wheel-template > div:nth-child(3) {
    margin: 0;
    margin-top: -40px;
    transform: scale(0.8);
  }
  .button {
    margin-top: -30px;
  }
}
/*+ =================   VERY SMALL SCREENS   ================== */

@media (max-width: 300px) {
  /*!  ==============   header  ================  */
  .spin-wheel-template > div:nth-child(1) {
    font-size: 28px;
  }
  /*!  ==============   caption  ================  */
  .spin-wheel-template > div:nth-child(2) {
    margin-top: 0px;
  }
  /*!  ==============   wheel  ================  */
  .spin-wheel-template > div:nth-child(3) {
    margin: -62px 0 -70px;
    transform: scale(0.6);
  }
}

.message {
  margin-top: -40px;
  margin-bottom: 20px;
}
