.PvPmodeResultsTemplate {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  width: 100%;
}

.PvPmodeResultsBox {
  margin-top: 290px;
}

@media (max-width: 650px) {
  .PvPmodeResultsBox {
    margin-top: 195px;
  }
}
