.header {
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
}

.header span {
  background: var(--blue, linear-gradient(180deg, #2e95fa 0%, #1872ca 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.headerCaption {
  font-family: Verdana;
  letter-spacing: -0.3px;
}

.captionButtonsContainer{
  position: relative;
}

.buttons-container {
  position: absolute;
  display: flex;
  margin-top: 22px;
  gap: clamp(12px, 1.8vw, 24px);
  z-index: 1;
  bottom: 0px;
}
a {
  text-decoration: none;
}
.playFree {
  width: clamp(158px, 23.5vw, 240px) !important;
  height: clamp(48px, 6.9vw, 70px) !important;
}
.playFree div {
  font-size: clamp(18px, 2.9vw, 30px) !important;
}
.playPrize {
  width: clamp(180px, 30vw, 310px) !important;
  height: clamp(48px, 6.9vw, 70px) !important;
}
.playPrize div {
  font-size: clamp(18px, 3vw, 30px);
}
.buttons-container img {
  width: clamp(2.55em, 5.8vw, 4.5em);
  pointer-events: none;
}

.playPrize img {
  width: clamp(2em, 2.2vw, 5.5em);
}
