.einsteinQuizCardContainer {
  position: relative;
  width: 432px;
  min-width: 432px;
  height: 290px;
  padding: 20px 20px 15px 20px;
  border-radius: 24px;
  box-sizing: border-box;
}

.textAndButtonContent {
  position: relative;
  top: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 53%;
}

.titleText {
  text-shadow: 1px 4px 8px #00000055;
}

.bodyText {
  text-shadow: 1px 1px 8px #00000055;
}

.extraInfoContainer {
  width: 40%;
  position: absolute;
  top: 9%;
  text-transform: uppercase;
}

.extraInfoText {
  color: rgba(255, 255, 255, 0.531);
  letter-spacing: -0.3px;
}

.dateInfoContainer {
  position: absolute;
  top: 6%;
  padding: 8px 10px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.405);
}

.imageContent {
  position: absolute;
  right: -30px;
  top: -30px;
  z-index: 0;
  width: 300px;
}

.playButton {
  border: none;
  position: relative;
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
  height: 56px;
  width: 100%;
  border-radius: 16px;
}

@media screen and (max-width: 768px) {
  .einsteinQuizCardContainer {
    width: 222px;
    min-width: 222px;
    height: 149px;
    padding: 10px 6px 5px 6px;
    border-radius: 16px;
  }

  .titleText {
    font-size: 16px;
    font-family: Gilroy;
    letter-spacing: -0.3px;
  }

  .bodyText {
    font-size: 8px;
  }

  .extraInfoContainer {
    top: 10%;
}

  .extraInfoText {
    color: rgba(255, 255, 255, 0.531);
    font-size: 16px;
  }

  .dateInfoContainer {
    padding: 8px 6px;
  }

  .dateText {
    font-size: 8px;
  }

  .playButton {
    height: 28px;
    border-radius: 8px;
  }

  .buttonText {
    font-size: 12px;
  }

  .imageContent {
    width: 150px;
  }
}
