/* roboto-condensed-300 - greek */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-300.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-300.svg#RobotoCondensed")
      format("svg"); /* Legacy iOS */
}
/* roboto-condensed-300italic - greek */
@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 300;
  src: url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-300italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-300italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-300italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-300italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-300italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-300italic.svg#RobotoCondensed")
      format("svg"); /* Legacy iOS */
}
/* roboto-condensed-regular - greek */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-regular.svg#RobotoCondensed")
      format("svg"); /* Legacy iOS */
}
/* roboto-condensed-italic - greek */
@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-italic.svg#RobotoCondensed")
      format("svg"); /* Legacy iOS */
}
/* roboto-condensed-700 - greek */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-700.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-700.svg#RobotoCondensed")
      format("svg"); /* Legacy iOS */
}
/* roboto-condensed-700italic - greek */
@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-700italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-700italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-700italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-700italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Roboto_Condensed/roboto-condensed-v25-greek-700italic.svg#RobotoCondensed")
      format("svg"); /* Legacy iOS */
}

/* gilroy font */

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"),
    url("./assets/fonts/Gilroy/Gilroy-ExtraBold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Roboto Condensed", "Gilroy", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
