.storeBoosterStoreContainers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: auto;
  height: auto;
}

.storeBoosterStoreEntire {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.storeBoosterStoreContainers > div:last-child img{
  width: 50%;
  padding: 8px;
}

@media screen and (max-width: 768px) {
  .storeBoosterStoreContainers {
    flex-wrap: wrap;
  }

  .cardsTitles {
      font-size: 24px;
  }

  }

  @media screen and (max-width: 485px) {
    .storeBoosterStoreContainers {
      display: grid;
      grid-template-columns: auto auto auto;
    }
    
    }