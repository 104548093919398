.box {
  /* position: absolute; */
  top: 20%;
  left: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 50px;
  border-radius: 16px;
}

.red {
  background: linear-gradient(180deg, #ff6e6e -17.86%, #d82929 107.14%);
}

.green {
  background: linear-gradient(
    180deg,
    rgba(112, 227, 41, 0.8) 0%,
    rgba(55, 151, 22, 0.8) 100%
  );
}

/* -------- Smaller Screens -------- */
@media (max-width: 650px) {
  .box {
    top: 12%;
    left: 5%;
    width: 30%;
    height: auto;
    border-radius: 16px;
  }

  .mobileText {
    font-size: 12px;
  }
}
