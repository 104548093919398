.avatar {
  display: flex;
  border-radius: 50%;
  border: 4px solid #0075ff;
  background-color: #ffffff;
  overflow: hidden;
  align-items: center;
  justify-content: space-evenly;
  align-content: center;
  margin: 0.2rem;
}

.avatarContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  transform: scale(1);
}

.editAvatarBadge {
  z-index: 10;
  position: absolute;
  left: 90px;
  top: 100px;
}

@media screen and (max-width: 768px) {
  .editAvatarBadge {
    left: 60px;
    top: 70px;
  }
}
