.login-slider {
  display: flex;
  border-radius: 50px;
  border: 1px solid #bababa;
  background: rgba(0, 0, 0, 0.1);
  padding: 3px;
  /* justify-content: space-around; */
  width: 305px;
  height: 48px;
}

.button {
  flex: content;
  /* min-width:160px;  */
  padding: 0 10px;
  display: flex;
  background: none;
  border: none;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  border-radius: 51px;
  --background-gradient: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
}

.active {
  background: var(--background-gradient);
}

@media screen and (max-width: 768px) {
  .login-slider {
    width: 100px;
    height: 42px;
    margin: 0px 20px 0px 20px;
  }
}
