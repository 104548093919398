.tournamentCardsWrapperLoggedIn,
.tournamentCardsWrapperLoggedOut {
  display: flex;
  justify-content: flex-start;
  gap: 25px;
  width: calc(100vw - 110px);
  min-width: 370px;
  overflow-x: auto;
  padding-top: 30px;
}

.tournamentCardsWrapperLoggedOut {
  width: calc(100vw - 13px);
}

@media screen and (max-width: 768px) {
  .tournamentCardsWrapperLoggedIn,
  .tournamentCardsWrapperLoggedOut {
    margin-left: 13px;
    padding-top: 25px;
    width: calc(100vw - 13px);
  }
}
