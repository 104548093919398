.entireContainer {
  width: auto;
  height: auto; /* Set height to auto */
  display: flex;
  flex-direction: row;
  padding-left: 2%;
  padding-right: 2%;
  justify-content: space-between;
}

.playerBox {
  width: 48%;
  height: auto; /* Set height to auto */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5%;
}

.opponentBox {
  width: 48%;
  height: auto; /* Set height to auto */
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 5%;
}

.avatarBox {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scoreBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.scoreAndNumber {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}

.scoreStyling {
  color: #a1a0a4;
  font-size: 17px;
}
