.container {
  /* position: absolute; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  top: 20%;
  right: 7%;
  width: 173px;
  height: 50px;
  border-radius: 16px;
  border: 1px solid #bababa;
  background: #ffffff80;
}
/* -------- Smaller Screens -------- */
@media (max-width: 650px) {
  .container {
    top: 12%;
    right: 5%;
    width: 30%;
    height: auto;
    border-radius: 16px;
  }

  .mobileText {
    font-size: 12px;
  }
}
