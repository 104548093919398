.formContainer {
  margin: 0 auto;
  padding-top: clamp(0px, 10px + (730px - 100vh) * 1, 600px);
  width: clamp(343px, calc(343px + (432 - 343) * ((100vw - 400px) / (660 - 400))), 432px);
}

.formContainer h2 {
  text-align: center;
  margin: 12px 0 35px 0;
}

.formContainer h2 > div {
  font-size: clamp(32px, calc(32px + (40 - 32) * ((100vw - 400px) / (660 - 400))), 40px);
}

.formInputsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputContainer {
  position: relative;
}

.inputContainer input {
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  border-radius: 16px;
  padding: 0 20px 0 48px;
  border: 1px solid #bababa;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  font-family: Gilroy;
  font-weight: 400;
}

.inputContainer img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 16px;
}

.errorMessage {
  color: red;
  position: absolute;
}

.linkArea > div:nth-child(1) {
  text-align: end;
}
.linkArea > button {
  margin: 30px auto 0;
  width: 95%;
  height: clamp(60px, calc(60px + (70 - 60) * ((100vw - 400px) / (660 - 400))), 70px);
}

.linkArea > button div {
  font-size: clamp(24px, calc(24px + (30 - 24) * ((100vw - 400px) / (660 - 400))), 30px);
}

.linkArea :nth-child(3) {
  margin-top: 16px;
  text-align: center;
}

.linkButton {
  border: none;
  background: none;
  color: #257cff;
  font-size: 16px;
}

.bottomSection {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 40px;
}

.termsAndPrivacyArea {
  margin: 30px 0;
  text-align: center;
}

@media screen and (max-width: 650px) {
  .bottomSection {
    margin-top: 15px;
    gap: 15px;
  }
}
