.question {
  margin: 56px 0 80px 0;
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.buttonsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  column-gap: 37px;
  row-gap: 24px;
}

@media (max-width: 650px) {
  .question {
    margin: 20px 0 20px 0;
  }
}
