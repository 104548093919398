.storeBoosterGroupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
}

.storeBoosterGroupCards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

@media screen and (max-width: 768px) {

    .cardsTitles {
        font-size: 24px;
    }
    
    }