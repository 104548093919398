.cardContainer {
    width: 204px;
    height: 248px;
    border: solid 1px #BABABA;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction:column;
    justify-content: space-around;
    margin: 10px;
    background: #FFFFFF80;
    box-sizing: border-box;

}

.coinsBuyButton {
    background: linear-gradient(180deg, #FFCE0D 0%, #FFAF06 100%);
}

@media screen and (max-width: 768px) {

    .cardContainer{
        width: 100px;
        height: 158px;
        margin: 8px;
    }

    .coinsIcon {
        width: 100%;
    }

    .coinsAmount {
        font-size: 14px;
    }

    .coinsBuyButton {
        height: 18%;
    }

    .coinsBuyButton > div { /*to be changed */
        font-size: 14px;
    } 

}