.box-container {
  width: 432px;
  height: 362px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #bababa;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  padding: 0 16px 12px;
  box-sizing: border-box;
}

.statisticsContainer {
  display: flex;
  margin-bottom: 20px;
}

.box-container > button {
  width: 93%;
}

.text-container {
  letter-spacing: -0.3px;
  text-align: center;
}
.header-text {
  color: #0075ff;
  font-size: 24px;
}

.caption-text {
  font-size: 16px;
}

.notifications-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 146px;
  width: 100%;
}

@media (max-width: 650px) {
  .box-container {
    width: 342px;
    height: 328px;
  }
  .box-container > button {
    height: 60px;
  }

  .box-container > button div {
    font-size: 24px;
  }
  .notifications-container {
    height: 130px;
  }
}
