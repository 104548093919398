.switch input[type='checkbox'] {
  height: 0;
  width: 0;
  display: none;
}

.switch label {
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 20px;
  background: lightgray;
  display: block;
  border-radius: 100px;
  position: relative;
}

.switch label:after {
  content: '';
  position: absolute;
  top: -3px;
  left: 0px;
  width: 23px;
  height: 23px;
  background: whitesmoke;
  border-radius: 90px;
  transition: 0.3s;
  border: 1px solid lightgray;
}

.switch input:checked + label {
  background: #bada55;
}

.switch input:checked + label:after {
  left: calc(100%);
  transform: translateX(-100%);
  background: darkgreen;
}

.switch label:active:after {
  width: 50px;
}

@media screen and (max-width: 350px) {
  .switch {
    transform: scale(0.8);
  }
}
