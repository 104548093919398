.comingSoonWrapper {
  position: relative;
  /* I added this only for better layout on 'Profile Template' */
  display: grid;
  grid-row: span 3;
}

.comingSoonContent {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
  /* text-transform: uppercase; */
  text-align: center;
  z-index: 2;
}

.blurredChildren {
  filter: blur(10px);
}

.heading {
  text-transform: uppercase;
  position: relative;
  background: linear-gradient(180deg, #2e95fa 0%, #1872ca 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: clamp(40px, 3vw, 70px);
  top: -30px;
}

.goldHeading {
  position: relative;
  background: linear-gradient(180deg, #f2be02 0%, #ea9e05 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  font-size: clamp(40px, 3vw, 70px);
  top: -30px;
  text-shadow: 
    0 0 5px rgba(239, 159, 11, 0.8),   /* Outer dark shadow */
    0 0 10px rgba(255, 223, 0, 0.8), /* Yellow glow */
    0 0 20px rgba(255, 223, 0, 0.6),
    0 0 30px rgba(255, 223, 0, 0.4),
    0 0 40px rgba(255, 223, 0, 0.2);
}

.text {
  position: relative;
  font-size: clamp(16px, 3vw, 30px);
  top: -20px;
  text-shadow: 2px 2px black;
}

.goldText {
  position: relative;
  font-size: 30px;
  top: -20px;
  text-shadow: 2px 2px black;
}

.einsteinImage {
  width: clamp(209px, 25%, 290px);
}

.einsteinImageWrapper {
  position: relative;
  z-index: -10;
  padding-bottom: 25px;
}
