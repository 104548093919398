.chattingPersonListWrapper {
  width: clamp(200px, calc(335px - 0.1 * (1860px - 100vw)), 335px);
  height: 400px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

@media screen and (max-width: 768px){
  .chattingPersonListWrapper {
    width: 342px;
    padding: 0 10px;
    height: calc(100vh - 450px);
    min-height: 70px;
  }
}
