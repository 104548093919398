.pageSeperator {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text {
  min-width: fit-content;
  margin:0 20px;
}
