.waitingScreenContent {
  text-align: center;
}

.waitingScreenContent > img {
  border: 4px solid #0075ff;
  border-radius: 50%;
  box-shadow: 0px 0px 13px #0075ff;
  margin: 160px 0 20px;
}

@media (max-width: 660px) {
  .waitingScreenContent > div:first-child {
    font-size: 35px;
  }
  .waitingScreenContent > img {
    width: 118px;
    margin: 150px 0 20px;
  }
}

@media (max-height: 720px) {
  .waitingScreenContent > img {
    margin: 10vh 0 20px;
  }
}
