.tournamentJackpot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  min-width: 375px;
}

.tournamentJackpotTextBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
  gap: 20px;
  max-width: 320px;
  border-radius: 16px;
  border: 1px solid #bababa;
  backdrop-filter: blur(15px);
  padding: 12px 16px 12px;
  background-color: rgba(255, 255, 255, 0.462);
}

.playButton {
  width: 100%;
  padding: 10px 0px;
}

.playButton div {
  font-size: clamp(25px,6vw,28px);
}

.blue {
  color: #0075ff;
  font-size: clamp(26px,5vw,28px);
  text-align: center; /* Center the text */
  word-wrap: break-word; /* Allow text to wrap if it's too long */
}

.grouped {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.folded {
  text-align: center; /* Center the text */
  word-wrap: break-word; /* Allow text to wrap if it's too long */
}

/* Media Queries  */
@media screen and (max-width: 650px) {
  .mobileText {
    /* font-size: 16px; */
    text-align: center; /* Center the text */
    word-wrap: break-word; /* Allow text to wrap if it's too long */
  }

  .tournamentJackpotTextBox {
    width: 80%;
  }
}
