.walletTemplateWrapper {
  margin: 120px 0 0 100px;
  width: calc(100% - 100px);
}

.walletTemplateWrapper > div:nth-of-type(1) {
  text-align: center;
  margin-bottom: 20px;
  font-size: clamp(32px, 7vw, 50px);
}

.walletTemplateContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

/*= ====================  MEDIA QUERIES =======================  */
@media screen and (max-width: 1240px) {
  .walletTemplateWrapper {
    width: fit-content;
    margin: 120px auto 120px;
    padding-left: 100px;
  }

  .walletTemplateContainer {
    width: fit-content;
    flex-direction: column;
  }

  /*! ===== LEFT BANNER YELLOW BOX  ====== */
  .walletTemplateContainer > div:nth-of-type(1) {
    /* display: none; */
  }

  /*! ===== RIGHT BANNER YELLOW BOX  ====== */
  .walletTemplateContainer > div:nth-of-type(3) {
    box-sizing: border-box;
    width: 100%;
    height: 0px;
    min-width: 600px;
    border-radius: 16px;
    margin: 50px auto 0;
    padding: 70px 50px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
  /*! ===== RIGHT BANNER IMAGE ====== */
  .walletTemplateContainer > div:nth-of-type(3) img {
    height: 170px;
    width: fit-content;
    position: static;
    transform: translateY(-10%);
  }
  /*! ===== RIGHT BANNER YELLOW FILTER ====== */
  .walletTemplateContainer > div:nth-of-type(3)::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 50%,
      #ffb006b9 75%,
      #ffaf06 100%
    );
    border-radius: 16px; /* for hidding the edges of the filter */
  }
  /*! ===== RIGHT BANNER TEXT ====== */
  .walletTemplateContainer > div:nth-of-type(3) div {
    margin: 0;
    width: fit-content;
    text-align: start;
    z-index: 1;
  }
  /*! ===== RIGHT BANNER 'COMING SOON' ====== */
  .walletTemplateContainer > div:nth-of-type(3) > div > div:nth-of-type(2) {
    line-height: 30px;
  }
}

@media screen and (max-width: 768px) {
  .walletTemplateWrapper {
    padding: 0;
  }
}

@media screen and (max-width: 650px) {
  .walletTemplateContainer {
    margin: 0 auto;
    padding-bottom: 0px;
    align-items: center;
  }

  .walletTemplateContainer > div:nth-of-type(3) {
    box-sizing: border-box;

    height: 93px;
    flex-shrink: 0;
    min-width: unset;

    margin-top: 50px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }

  .walletTemplateContainer > div:nth-of-type(3) div {
    margin-left: 20px;
  }
  .walletTemplateContainer > div:nth-of-type(3) img {
    height: unset;
    width: fit-content;
    position: static;
    transform: translateY(-20%);
  }
}
