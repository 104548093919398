.container {
  position: relative;
}

.bottomImageContainer {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
