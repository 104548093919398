.profileTemplate {
  margin: 120px 20px 50px 100px;
  padding-left: 20px;
  display: grid;
  grid-template-areas:
    'profileInfo profileStats'
    'profileButtons profileButtons';
  gap: 20px;
  box-sizing: border-box;
}

.inventorySection {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  height: fit-content;
}

.inventorySection > div {
  justify-content: center;
  margin: 0 auto;
}

.profileInfoSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: fit-content;
  width: 100%;
  align-items: center;
}

.profileUserStatistics {
  grid-row: span 3; /* Adjust based on your needs */
}

.profileUserAssets {
  width: fit-content;
}

.avatar {
  z-index: 5;
  border: 2px solid #bac3fd;
  border-radius: 50%;
}
.avatarArea {
  display: flex;
  align-items: center;
  gap: 15px;
}

.avatarSelectionModule {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the start of the flex container */
  background: #f8f4f4;
  border-radius: 24px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 767px;
  min-width: 250px;
  width: 85vw;
  max-height: 810px;
  height: calc(98vh - 50px); /* Keep the desired height */
  min-height: 150px;
  z-index: 60;
  text-align: center;
  overflow: auto;
  padding: 0 20px 20px 20px;
}

.topAreaModuleSelectAvatar {
  margin: 20px 0 20px;
  position: relative;
}

.closeModuleButton {
  border-radius: 50%;
  background: none;
  position: absolute;
  top: 0%;
  right: 0px;
}

.closeModuleButton:hover {
  cursor: pointer !important;
}

.moduleAvatarsArea {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  max-height: 700px;
  min-height: 50px;
  gap: 20px;
  overflow: auto;
}

.moduleAvatarsArea::-webkit-scrollbar-track {
  background: #0000002f;
  border-radius: 10px;
}

.moduleAvatarsArea::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
  border-radius: 10px;
}

.moduleAvatarsArea::-webkit-scrollbar {
  width: 10px;
}

.avatarSelectionModuleBackground {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(32, 18, 2, 0.673);
  z-index: 60;
  backdrop-filter: blur(7px);
}

/* ====================  MEDIA QUERIES ======================= */

@media screen and (max-width: 1050px) {
  .profileTemplate {
    padding: 0 20px 0 130px;
    width: 100%;
    margin: 120px auto 120px;
    display: flex;
    flex-direction: column;
  }
  .avatarArea {
    flex-direction: column;
  }
  .moduleAvatarsArea {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .profileTemplate {
    padding: 0 20px;
    margin-left: 0;
    margin: 120px auto 120px;
    width: fit-content;
  }

  .profileInfoSection {
    margin-left: 0;
  }
}

@media screen and (max-width: 694px) {
  .moduleAvatarsArea {
    grid-template-columns: repeat(3, 1fr);
    max-height: calc(90vh - 80px);
    gap: 0px;
  }
  .avatarSelectionModule {
    max-height: 80%;
  }

  .profileUserAssets {
    margin: 0 auto;
  }
}

@media screen and (max-width: 366px) {
  .moduleAvatarsArea {
    grid-template-columns: repeat(2, 1fr);
  }
}
