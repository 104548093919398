.boosterTemplateContainer {
  display: flex;
  justify-content: center;
  gap: 24px;
  padding-top: 100px;
  min-width: 100%;
}

.boostersToPlaySection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.grey {
  color: #717380;
  margin: 56px 0 80px 0;
}

@media screen and (max-width: 650px) {
  .boosterTemplateContainer {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 120px;
    padding-bottom: 190px;
    gap: 0;
    overflow: hidden;
  }
  .boostersHeader {
    margin-top: 0px;
  }
  .boostersToPlaySection {
    gap: 8px;
    margin: 0;
    margin-bottom: -20px;
    padding: 0;
  }
  .boostersToPlaySection > button {
    position: absolute;
    bottom: auto;
    top: 800px;
  }
}
