.imagesContainer {
  position: relative;
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

.primaryImage {
  position: relative;
  -webkit-mask-image: linear-gradient(to bottom, black, black 10%, transparent 90%);
  mask-image: linear-gradient(to bottom, black, black 80%, transparent 100%);
}

.secondaryImage {
  position: absolute;
  z-index: -1;
}

@media screen and (max-width: 768px) {
  .primaryImage {
    -webkit-mask-image: linear-gradient(to bottom, black, black 100%, transparent 90%);
    mask-image: linear-gradient(to bottom, black, black 75%, transparent 97%);
  }
}
