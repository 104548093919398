.grey {
  color: rgba(0, 0, 0, 0.4);
}

.ticketBox {
  gap: 16px;
}

.ticketBox span {
  display: flex;
  background: rgba(255, 255, 255, 0.5);
  width: 205px;
  align-items: center;
  padding: 15px;
  gap: 23px;
  border-radius: 8px;
  border: 1px solid var(--gold, #ffce0d);
}

.profileExtraSection {
  margin-top: 140px;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
}

.medalArea {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 25px;
}

.categoriesArea {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 25px;
}

.medals,
.categories {
  flex-wrap: wrap;
  display: flex;
  gap: 25px;
}
.infoLinesArea {
  margin-top: 50px;
}
.infoAreaHeader {
  display: flex;
  justify-content: center;
}
.line {
  display: flex;
  justify-content: space-between;
  height: max-content;
  height: 60px;
}
.line :first-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.line :nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.hr {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.11);
}

@media screen and (max-width: 1050px) {
  .profileExtraSection {
    margin: 0;
    width: fit-content;
    width: 100%;
    min-width: 320px;
    margin: 0;
    gap: 20px;
    padding: 0;
    top: 35%;
  }

  .infoLinesArea {
    margin-top: 0;
  }
  .categories {
    gap: 15px;
  }
}

@media screen and (max-width: 650px) {
  .profileExtraSection {
    width: 100%;
    min-width: 320px;
    margin: 0;
    gap: 20px;
    padding: 0;
    top: 35%;
  }
  .infoLinesArea {
    margin: 0;
  }
  .categories {
    gap: 15px;
  }
}
