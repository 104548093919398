.panel {
  position: relative;
  margin-bottom: 120px;
  margin-top: 20px;
}
.red {
  background-color: #e64caa; /* Adjust color values as needed */
}
.blue {
  background-color: #3385ff;
}
.green {
  background-color: #1dc35f;
}
.content img {
  width: clamp(96px, calc(200px - ((1470px - 100vw) * 0.169)), 200px);
  position: absolute;
  bottom: 0;
  right: 0;
}
.textContainer {
  position: absolute;
  bottom: 10%;
  left: 2%;
}
/*! ===== the header of the game panel ===== */
.textContainer > div:nth-of-type(1) {
  font-size: clamp(20px, calc(30px - ((1470px - 100vw) * 0.0165)), 30px);
  text-wrap: nowrap;
}
/*! ===== the caption of the game panel ===== */
.textContainer > div:nth-of-type(2) {
  font-size: clamp(12px, calc(18px - ((1470px - 100vw) * 0.012)), 18px);
}
.rectangle {
  width: clamp(222px, calc(432px - ((1470px - 100vw) * 0.34)), 432px);
  height: clamp(125px, calc(240px - ((1470px - 100vw) * 0.184)), 240px);
  border-radius: clamp(16px, calc(16px + (24 - 16) * ((100vw - 860px) / (1470 - 860))), 24px);
}
