.carouselContainer {
  display: flex;
  position: relative;
  gap: 30px;
}

.avatarsSection {
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatarsSectionButton {
  cursor: pointer;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin: 0 !important;
  width: min-content !important;
  background: none;
}

.icon,
.active {
  margin: -5px;
  opacity: 0.5;
  border: 4px solid #0075ff;
  box-shadow: 0px 0px 13px #0075ff;
  border-radius: 50%;
  pointer-events: none;
}

.active {
  z-index: 5;
  opacity: 1;
  width: 130px;
  height: 130px;
  background: azure;
}
.directionButtons {
  display: flex;
  gap: 350px;
  position: absolute;
  top: 50px;
  left: -78px;
}

@media screen and (max-width: 650px) {
  .directionButtons {
    display: flex;
    gap: 250px;
    left: -33px;
    top: 150%;
  }

  .directionButtons img {
    width: 48px;
  }
}
