.blockedPersonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  border: 1px solid #bababa;
  padding: 0 20px;
  border-radius: 10px;
  transform: skewX(-20deg);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  box-sizing: border-box;
}

.blockedPersonContainer img {
  align-items: center;
  padding-bottom: 7px;
}

.blockedUserInfos {
  display: flex;
  align-items: center;
  gap: 5px;
  transform: skewX(20deg);
}

.levelText {
  color: #717380;
}

.blockedPersonContainer button {
  background: linear-gradient(180deg, #2e95fa 0%, #1872ca 100%);
  width: 100px;
  height: 32px;
  border-radius: 7px;
  border: none;
}

.textButton {
  transform: skewX(20deg);
}
