.tournamentPanelsContainer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    overflow-x:scroll;
    padding-top: 40px;
    height: 394px;
    margin-bottom: 1px;
}

.loadingSpinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
    .tournamentPanelsContainer {
      padding-top: 30px;
      height: 243px;
      margin-left: 10px;
    }
    
}

