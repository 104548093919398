.referBanner {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 15px;
  width: 432px;
  height: 120px;
  border-radius: 8px;
  border: 1px solid #bababa;
  background: rgba(255, 255, 255, 0.5);
}

.upperSection {
  width: 100%;
  display: flex;

  gap: 10px;
  justify-content: center;
  align-items: flex-start;
}

.upperSectionText {
  display: flex;
  flex-direction: column;
}

.buttonSection {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.refferalCodeButton {
  height: 32px;
  width: 189px;
  border-radius: 16px;
  border: 1px solid #bababa;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  color: #2e95fa;
}

.copyButton,
.sendButton {
  width: 90px;
  height: 32px;
  border-radius: 40px;
  border: none;
  background: var(--blue, linear-gradient(180deg, #2e95fa 0%, #1872ca 100%));
}

.grey {
  color: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 650px) {
  .referBanner {
    width: 344px;
    height: 110px;
  }
  .buttonSection {
    gap: 8px;
  }

  .copyButton,
  .sendButton {
    width: 60px;
  }
}
