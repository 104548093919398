.storeWheelSectionContainers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.storeWheelSectionContainers > div:first-child img{
  width: 70%;
}

.storeWheelSectionInfoBox {
  display: flex;
  justify-content: center;
}

.storeWheelSectionEntire {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.buttonBlackBackground {
  background: #00000010;
}

.blue {
  background: linear-gradient(180deg, #2e95fa 0%, #1872ca 100%);
}

@media screen and (max-width: 768px) {

  .cardsTitles {
      font-size: 24px;
  }
  
  }