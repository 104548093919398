.leaderboardEntry {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 50px;
  padding: 6px;
  min-width: 290px;
}

.skewedBorder {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: skewX(-20deg);
  min-width: 220px;
}

.entryContent {
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 10px;
}

.position {
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
}

.userData {
  display: flex;
  margin-left: 10px;
  align-items: center;
  min-width: 100px;
}

.username {
  font-weight: bold;
  width: clamp(80px, 19.7vw, 300px);
  text-wrap: wrap;
  overflow-wrap: break-word;
}

.stat {
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: clamp(38px, 11.7vw, 250px);
}
.medals {
  display: flex;
}

.totalCorrectAnswers{
  display: flex;
  justify-content: center;
  width: 280%;
}

.medal {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ffd700; /* Medal color, adjust as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.medalIcon {
  font-size: 14px; /* Adjust the icon size as needed */
  color: #ffffff; /* Icon color, adjust as needed */
}
.points {
  font-weight: bold;
  margin-right: 20px;
  width: 100px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 50px;
}
.grey {
  color: #cdcdcd;
}
.gold {
  color: #ffce0d;
}

.silver {
  color: #717380;
}

.bronze {
  color: #b36d5e;
}

.self {
  color: #7c660f;
}

@media screen and (max-width: 1150px) {
  .username {
    width: clamp(80px, 20.2vw, 300px);
  }
}

@media screen and (max-width: 1030px) {
  .username {
    width: clamp(80px, 20.5vw, 300px);
  }
}

@media screen and (max-width: 1000px) {
  .username {
    width: clamp(80px, 20.5vw, 300px);
  }
}

@media screen and (max-width: 950px) {
  .username {
    width: clamp(80px, 21vw, 300px);
  }
}

@media screen and (max-width: 920px) {
  .username {
    width: clamp(80px, 21.5vw, 300px);
  }
}

@media screen and (max-width: 768px) {
  .leaderboardEntry {
    margin-bottom: 10px;
    margin-left: 10px;
    padding: 8px;
  }
  .index {
    margin: 0;
    margin-right: -5px;
    margin-left: -10px;
  }
  .entryContent {
    margin-left: 0px;
  }

  .username {
    width: clamp(80px, 17vw, 200px);
  }
}

@media screen and (max-width: 500px) {
  .username > div {
    width: clamp(40px, 13vw, 300px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media screen and (max-width: 412px) {
  .stat > div {
    width: clamp(33px, 9.7vw, 850px);
  }
}
