.cashTournamentsSection {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  gap: 24px;
}

.cashTournamentsSection > div {
  margin-bottom: 150px;
}

@media screen and (max-width: 650px) {
  .cashTournamentsSection {
    margin-top: 0px;
    padding-bottom: 30px;
  }
  .cashTournamentsSection > div {
    margin: 30px;
  }
}
