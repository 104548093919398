.questionAnswersContainer {
  text-align: center;
  padding: 200px 20px 50px;
}

/*+ ===================    SMALL SCREENS    =======================  */
@media (max-width: 650px) {
  /*?  ========   number of questions    =========== */
  .questionAnswersContainer > div:first-child {
    font-size: 20px;
  }
  /*?  ========   counter-down number   =========== */
  .questionAnswersContainer > div:nth-child(2) > div {
    font-size: 30px;
    margin: 0; /* so the margin */
  }

  /*?  ========   counter-down bar   =========== */
  .questionAnswersContainer > div:nth-child(2) > div:nth-child(2) {
    height: 18px;
  }

  /*?  ============   question   =============== */
  .questionAnswersContainer div:nth-child(3) {
    font-size: 24px;
  }
  /*?  ============   answers area   =============== */
  .questionAnswersContainer div:nth-child(4) {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  /*?  ============   answer   =============== */
  .questionAnswersContainer button {
    height: 59px;
  }
  /*?  ============   answer text   =============== */
  .questionAnswersContainer button > div {
    font-size: 16px;
  }
}

.iconAnimation {
  position: fixed;
  top: 0; /* Starts from the top */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  z-index: 10;
  pointer-events: none;
}

.iconAnimation img {
  width: clamp(150px, 30vw, 300px);
}

@media screen and (max-width: 650px) {
  .questionAnswersContainer {
    padding-top: 120px;
  }
}
