.friendsV2TemplateContainer {
  /* border: solid green; */
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

.header {
  font-size: clamp(24px, calc(32px - (1150px - 100vw) * 0.018), 32px);
}

.friendsV2TemplateContainer img {
  border-radius: 50%;
  border: 2px solid #ffce0d;
  border-radius: 50%;
}

.friendsRequestsContainer,
.friendsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.friendsRequestsContainer {
  margin-bottom: 40px;
}

.skewedBorder {
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: skewX(-20deg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px 5px 15px;
  box-sizing: border-box;
}

.avatarAndNameSection,
.buttonsSection {
  transform: skewX(20deg);
}

.avatarAndNameSection {
  display: flex;
  align-items: center;
  gap: 6px;
}

.buttonsSection {
  display: flex;
  gap: 15px;
}

.friendsV2TemplateContainer button {
  border: none;
  width: clamp(56px, calc(116px - (1150px - 100vw) * 0.133), 116px);
  height: 36px;
  border-radius: 10px;
  background: linear-gradient(180deg, #2e95fa 0%, #1872ca 100%);
  min-width: fit-content;
}

.friendsRequestsContainer .rejectButton {
  background: linear-gradient(180deg, #fd604b 0%, #e63434 100%);
}
.friendsRequestsContainer .acceptButton {
  background: linear-gradient(180deg, rgba(112, 227, 41, 0.8) 0%, rgba(55, 151, 22, 0.8) 100%);
}

.buttonText {
  font-size: clamp(14px, calc(16px - (1150px - 100vw) * 0.0044), 16px);
}

.noFriendsContainer {
  position: relative;
  top:50px;
  text-align: center;
}

.noFriendsContainer div{
  font-size:clamp(20px,5vw,40px);
}

@media screen and (max-width: 699px) {
  .friendsV2TemplateContainer img {
    border: none;
  }
}
