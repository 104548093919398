.profileTemplate {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  display: flex;
  box-sizing: border-box;
}

.profileTemplate > div:nth-of-type(2) {
  width:90%;
  min-width:150px;
  max-width:600px;
}

.profileInfoSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.avatar {
  z-index: 5;
  border: 2px solid #bac3fd;
  border-radius: 50%;
}
.avatarArea {
  display: flex;
  align-items: center;
  gap: 15px;
}

/* ====================  MEDIA QUERIES ======================= */

@media screen and (max-width: 1050px) {
  .profileTemplate {
    position: relative;
    width: 100%;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
  }

  .profileInfoSection {
    width: unset;
  }

  /*! ====   AVATAR/USERNAME SECTION  ==== */
  .profileTemplate > div:nth-of-type(1) > div:nth-of-type(1) {
    flex-direction: column;
  }
  /*! ====   TROPHY BUTTON   ==== */
  .profileTemplate
    > div:nth-of-type(1)
    > div:nth-of-type(2)
    > div:nth-of-type(1)
    > div:nth-of-type(1) {
    margin: 0 auto;
  }
  /*! ====   ADD FRIEND BUTTON   ==== */
  .profileTemplate
    > div:nth-of-type(1)
    > div:nth-of-type(2)
    > div:nth-of-type(1)
    > div:nth-of-type(1) {
    margin-bottom: 100px;
  }
  /*! ====   BLUE BUTTONS SECTION  ==== */
  .profileTemplate
    > div:nth-of-type(1)
    > div:nth-of-type(2)
    > div:nth-of-type(1)
    > div:nth-of-type(3) {
    flex-direction: column;
  }
  /*! ====   BLUE BUTTON ==== */
  .profileTemplate
    > div:nth-of-type(1)
    > div:nth-of-type(2)
    > div:nth-of-type(1)
    > div:nth-of-type(3)
    button {
    width: 100%;
  }

  /*! ====   USER STATISTICS SECTION  ==== */
  .profileTemplate > div:nth-of-type(2) {
    position: absolute;
    margin-top: 30px;
    width: 100%;
  }
}

@media screen and (max-width: 770px) {
  .profileTemplate {
    margin-bottom: 20px;
  }
  .profileInfoSection {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .profileTemplate {
    margin-top: -20px;
    padding: 0;
    margin-bottom: 20px;
    justify-content: center;
  }
    /*! ====   ADD FRIEND BUTTON   ==== */
    .profileTemplate
    > div:nth-of-type(1)
    > div:nth-of-type(2)
    > div:nth-of-type(1)
    > div:nth-of-type(1){
    margin-bottom: 50px;
  }
    /*! ====   BLUE BUTTONS SECTION  ==== */
    .profileTemplate
    > div:nth-of-type(1)
    > div:nth-of-type(2)
    > div:nth-of-type(1)
    > div:nth-of-type(3) {
    padding: 0 10px;
  }
}
