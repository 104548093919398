.text-container {
  max-width: 432px;
  min-width: 300px;
  width: 90vw;
  text-align: center;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  transition: max-height 3.5s linear;
  max-height: 0; /* Start as collapsed */
  overflow: hidden; /* for not the text be visible untill the box expands */
}

.expanded {
  max-height: 2000px; /* it will be auto with max value 2000 that it will never br reached */
  padding-bottom: 20px;
  border: 1px solid #bababa;
}

.header {
  margin-top: 38px;
  font-size: 24px;
}

.knowledge {
  padding: 0 16px;
  margin-top: 15px;
  text-align: center;
}

.caption {
  margin: 34px 0 10px;
  color: #717380;
}
