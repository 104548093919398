.container {
  display: flex;
  flex-direction: row;
  align-items: left;
  gap: 20px;
  width: 100%;
  margin-left: 100px;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
}
