.profileButtonSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.profileButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  gap: 8px;
  margin-bottom: 8px;
  width: 205px;
  height: 54px;
  border-radius: 16px;
  border: none;
  background: var(--blue, linear-gradient(180deg, #2e95fa 0%, #1872ca 100%));
}

@media screen and (max-width: 650px) {
  .profileButton {
    max-width: 400px;
    margin: 8px auto 0;
  }
}