.bannerContainer {
  border-radius: 24px;
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
  display: flex;
  flex-direction: column;
  padding-bottom: 90px;
  position: relative;
}

.bannerContainer img {
  position: relative;
  top: -5%;
}

.bannerContainer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    #27ff0600 40%,
    #ffba24a7 49%,
    #ffba24 51%,
    #fcbc3b 54%,
    #ffb00600 55%,
    #f7f7f700 100%
  );
}

.textContent {
  text-align: center;
  width: 200px;
  margin: 80px auto 0px;
}

/* targets 'COMING SOON' text */
.textContent div:nth-of-type(2) {
  opacity: 0.5;
  text-transform: uppercase;
}
