.answerButton {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: normal;
  letter-spacing: -0.3px;
  border-radius: 24px;
  border: 1px solid #bababa;
  background: rgba(255, 255, 255, 0.5);
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  transition: background-color 0.6s ease, box-shadow 0.6s ease; /* Smooth background-color transition */
}

.correctAnswerClass {
  background-color: #54be2e; /* Use a solid color */
  box-shadow: 0px 2px 2px 0px #a3ff6f inset,
    0px -3px 1px 0px rgba(0, 0, 0, 0.25) inset;
}

.wrongAnswerClass {
  background-color: #d82929; /* Use a solid color */
  box-shadow: 0px 2px 2px 0px #ffafaf inset,
    0px -3px 1px 0px rgba(0, 0, 0, 0.25) inset;
}

.selectedAnswerClass {
  border-radius: 24px;
  border: 1px solid #bababa;
  background-color: #006dd7; /* Use a solid color */
  box-shadow: 0px 2px 2px 0px #6db7ff inset,
    0px -3px 1px 0px rgba(0, 0, 0, 0.25) inset;
  animation: pulse 0.5s ease-in-out infinite;
  transition: background-color 0.6s ease, box-shadow 0.6s ease; /* Smooth background-color transition */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.correctAnswerClass .buttonText,
.wrongAnswerClass .buttonText,
.selectedAnswerClass .buttonText {
  color: white;
  transition: color 0.2s ease; /* Smooth text color transition */
}

.answerButton:not(.correctAnswerClass):not(.wrongAnswerClass):not(.selectedAnswerClass) {
  transition: none; /* Disable transition when reverting */
}
