/* Carousel.module.css */

.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: clamp(40px,10vw,80px);
}

.carousel-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  flex: 0 0 100%;
  box-sizing: border-box;
}

.dots {
  z-index: 0;
  position: absolute;
  bottom: clamp(10%,12vw,15%);
  left: calc(50% + 30px);
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 15px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #000000;
  border-radius: 50%;
  cursor: pointer;
}

.dotActive {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #ffc70c;
}

@media screen and (max-width: 768px) {
  .dots {
    left: 50%;
  }
}
