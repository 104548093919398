.cardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 204px;
    height: 408px;
    border-radius: 16px;
    border: 1px solid #BABABA;
    margin: 10px;
    background: #FFFFFF80;
    box-sizing: border-box;
    justify-content: space-evenly;
}

.multiplyCoinsContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
}
.multiplyCoinsInfo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px 10px 0px;
}

.boosterBuyButton {
    background: linear-gradient(180deg, #2E95FA 0%, #1872CA 100%);
}

.storeItemCardEnableBlueBoarder {
    border: 2px solid #2E95FA;
    position: relative;
    box-sizing: border-box;
}

.storeItemCardAddOn {
    position: absolute;
    top: 0;
    right: 0;
  }
  


@media screen and (max-width: 768px) {

    .cardContainer {
        width: 102px;
        height: 252px;
        margin: 8px;
        justify-content: space-evenly;
    }
    
    .topIcon {
        width: 100%;
        height: 30%;
    }
    .packageBoosterNum {
        font-size: 14px;
    }

    .multiplyCoins {
        font-size: 11px;
    }

    .boosterCoin {
        width: 50%;
        height: 100%;
    }

    .boosterBuyButton { 
        height: 10%;
    }
    .boosterBuyButton > div {  /* to be changed */
        font-size: 12px;
    }
  }