.chatTemplateWrapper {
  display: flex;
  gap: clamp(5px, calc(20px - 0.15 * (960px - 100vw)), 20px);
}

@media screen and (max-width: 768px){
  .chatWindowMobile {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 100px;
    background-color: white;
    z-index: 50;
    width: 100vw;
    min-width: 370px;
    max-width: 1560px;
    height: 100%;
  }
}
