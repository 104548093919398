.formContainer {
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  min-width: 250px;
  max-width: 500px;
  height: clamp(200px, 55vw, 320px);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: clamp(0px, calc(10px + (530px - 100vh) * 1), 600px);
}

.headerFormForgotPassword {
  text-align: center;
  font-size: clamp(25px, 7vw, 40px);
  margin-bottom: 20px;
}

.inputContainer {
  position: relative;
}

.inputContainer input {
  box-sizing: border-box;
  width: 100%;
  height: clamp(40px, 12vw, 56px);
  border-radius: 16px;
  padding: 0 20px 0 48px;
  border: 1px solid #bababa;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  font-family: Gilroy;
  font-weight: 400;
}

.inputContainer img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 16px;
}

.sendEmailButton {
  margin: 0 auto;
  width: 90%;
  height: clamp(50px, 13vw, 70px);
}

.sendEmailButton div {
  font-size: clamp(20px, 6vw, 30px);
}
