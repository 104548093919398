.panelIcons {
  display: flex;
  flex-direction: column;
  gap: 8px;

  /* background: #12151e80 20%; */

  /* border-radius: 64px; */
  padding: 6px;
}

.messageNotification{
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top:10px;
  right:0;
}

@media (max-height: 960px) {
  .panelIcons {
    gap: 5px;
    }
}
