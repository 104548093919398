.question {
  margin: 56px 0 80px 0;
}

.buttonsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  column-gap: 37px;
  row-gap: 24px;
}

@media (max-width: 650px) {
  .question {
    margin: 25px 0 25px 0;
  }
}