.MobileMenu {
  z-index: 50;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width:1560px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  height: 80px;
  border-radius: 24px 24px 0px 0px;
  border: 1px solid #d6d6d6;
  background-color: rgba(255, 255, 255, 0.9);
}
.panelIcons {
  display: flex;
  gap: 4px;
  padding: 12px;
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-start;
}

.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-width: 45px;
  max-width: 55px;
  text-align: center; /* Center the text */
}

.walletIcon {
  order: -1; /* Move the wallet icon to the beginning */
  flex-basis: auto; /* Allow it to take up the necessary space */
  margin-top: -50px;
}

.walletBubble {
  background-color: #fdfeff; /* Customize the bubble color */
  border-radius: 50%; /* Make it circular */
  padding: 8px; /* Adjust padding as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.blue {
  color: #257cff;
}

.mobileIconContainer {
  position: relative;
}

.messageNotification {
  background-color: red;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  top:2px;
  right:1px;
}