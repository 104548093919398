.didYouKnow-container {
  position: absolute; /* Change to absolute positioning */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Adjust for exact centering */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.didYouKnow-box {
  margin: -16px auto 20px;
}

@media (max-width: 650px) {
  .didYouKnow-container {
    transform: translate(-50%, -50%) scale(0.8);
  }
}
