.questionAnswersContainer {
  text-align: center;
  padding: 140px 20px 50px;
  min-width: 350px; /* Added minimum width */
}

/* ===================    SMALL SCREENS    ======================= */
@media (max-width: 650px) {
  /* =========   number of questions    ========== */
  .questionAnswersContainer > div:nth-child(2) {
    font-size: 20px;
  }
  /* =========   counter-down number   ========== */
  .questionAnswersContainer > div:nth-child(3) > div {
    font-size: 30px;
    margin: 0; /* Remove the margin */
  }

  /* =========   counter-down bar   ========== */
  .questionAnswersContainer > div:nth-child(3) > div:nth-child(2) {
    height: 18px;
  }

  /* ============   question   =============== */
  .questionAnswersContainer div:nth-child(4) {
    font-size: 24px;
  }
  /* ============   answers area   =============== */
  .questionAnswersContainer div:nth-child(5) {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  /* ============   answer   =============== */
  .questionAnswersContainer button {
    height: 59px;
  }
  /* ============   answer text   =============== */
  .questionAnswersContainer button > div {
    font-size: 16px;
  }
}

@media screen and (max-width: 650px) {
  .questionAnswersContainer {
    padding-top: 150px;
  }
}
