.chatWindow {
  width: clamp(350px, calc(100vw - 610px), 774px);
  height: 400px;
  padding: clamp(5px, calc(20px - 0.1 * (1160px - 100vw)), 20px) 15px 0;
  box-sizing: border-box;
  overflow: auto;
  max-width: 1560px;
  &::-webkit-scrollbar {
    /* hidding scrollbar for chrome, safari, opera */
    display: none;
  }
}

.dateText{
  text-align: center;
}

.fixedDateText {
  text-align: center;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .chatWindow {
    height: 94.3%;
    min-width: 370px;
  }
}
