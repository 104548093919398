.modalWithPaymentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top:140px;
  overflow-y: auto; /* Enable vertical scrolling */
  padding-bottom: 100px;
  min-width:300px;
}
.paymentForm {
  width: 90%;
  min-height: 440px;
  max-width: 400px;
  min-width: 300px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1),
    0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 10px 30px 30px 30px;
  background: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 7;
  margin: 0 auto;
}

.paymentElement {
  margin-top:20px;
  margin-bottom: 24px;
}

.payButton {
  height: clamp(40px,13vw,60px);
  width: 70%;
  margin: 0 auto;
}

.payButton div{
  font-size: clamp(18px,9vw,35px);
}

.payButton.disabled {
  background: rgb(249, 197, 113);
}

.closePurchaseModalButton {
  margin-left: 98%;
}

.closePurchaseModalButtonDisabled {
  opacity: 0.5;
}

.certificationLogosContainer {
  width:fit-content;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 10px auto 0px;
}

.logoIcon {
  margin:5px;
}

.guaranteeText1{
  text-align: center;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: clamp(20px,6vw,27px);
}

.guaranteeText2{
  text-align: center;
  margin-bottom: 30px;
  font-weight:300;
  margin-bottom:50px;
  font-size:clamp(16px,5vw,22px);
}