.settingsContentWrapper {
  display: flex;
  flex-direction: row;
  margin: 5px;
  width: 90%;
  text-align: left;
  justify-content: left;
}
.settingsContentWrapper button:hover {
  opacity: 0.8;
}
.settingsContentWrapper img {
  margin-left: 5px;
}

.settingstxtwrapper {
  flex: 2;
  align-self: center;
  /* margin-right: 20px; */
}

.settingsModaltext {
  text-align: center;
  /* margin-top: 5; */
}

.settingsonoffbtn {
  align-self: center;
  justify-content: center;
  /* margin-right: 38px; */
  width: max-content;
}

.settingstitletxt {
  /* color: #0b5eff;
  font-size: 25px; */
  margin-left: 10px;
  white-space: nowrap;
}

.settingsicon1 {
  width: 49px;
  height: auto;
}

.settingsicon2 {
  width: 46px;
  height: auto;
}

.settingsicon3 {
  width: 28px;
  height: auto;
}

.settingstermagreementwrapper {
  display: flex;

  gap: 20px;

  margin: 20px;
}

.settingscontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.settingstermsagreementtxt {
  color: #0075ff;
  text-decoration: underline;
}

.emptyButton {
  background: none;
  border: none;
}

.deleteButton {
  display: flex;
  width: 90%;
  margin-bottom: 10px;
  height: 54px;
  border: none;
  background: linear-gradient(90deg, #ff6e6e -9.35%, #d82929 100%);
  align-items: center;
  justify-content: center;
  gap: 20px;
  box-shadow: 0px 2px 2px 0px rgba(255, 175, 175, 1) inset;
  border-radius: 16px;
  box-shadow: 0px -3px 1px 0px rgba(0, 0, 0, 0.25) inset;
}

.languageMenu {
  position: absolute;
  top: 100px;
  right: 100px;
}

@media screen and (max-width: 367px) {
  .deleteButton {
    width: 85%;
  }
}
