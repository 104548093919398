.outgoingMessage,
.incomingMessage {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  padding: 5px 0;
}

.outgoingMessage {
  align-items: flex-end;
}

.messageText {
  color: #ffffff;
  background: linear-gradient(180deg, #2e95fa 0%, #1872ca 100%);
  max-width: clamp(50px, calc(500px - 12vw), 250px);
  width: fit-content;
  padding: 10px;
  border-radius: 16px 16px 4px 16px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: clamp(15px, 1.6vw, 20px);
}

.incomingMessage .messageText {
  border: 1px solid #bababa;
  border-radius: 16px 16px 16px 4px;
  background: none;
  background-color: white;
  color: black;
}

.timestampText {
  color: #717380;
}
