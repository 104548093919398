.chatWindowTopSectionContainer {
  background-color: #f8f6ff;
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: none;
  gap: 20px;
  padding: 8px 10px;
  box-sizing: border-box;
}

.backButton {
  display: flex;
  border: none;
  background: none;
}

.discusserAvatar {
  border: solid #bababa;
  border-radius: 50%;
  margin-right: -12px;
}
