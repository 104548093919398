.storeTicketSectionContainers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.storeTicketSectionInfoBox {
  display: flex;
  justify-content: center;
}

.storeTicketSectionEntire {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 42px;
}

.buttonBlackBackground {
  background: #00000010;
}

.yellow {
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
}

.cardsTitles {
  text-align: center;  
}

@media screen and (max-width: 768px) {

  .cardsTitles {
      font-size: 24px;
  }
  
  }