.leaderboardHeadings,
.leaderboardSpecialHeading,
.leaderboardTotalSpecialHeading {
  display: flex;
  align-items: end;
  margin-left: 150px;
  font-weight: bold;
  /* min-width: 300px; */
}

.trophy {
  margin-left: -30px;
  padding-right: 25px;
}
.leaderboardTotalSpecialHeading .trophy{
  min-width: 70px;
  text-align: center;
}
.lines > :first-child span {
  border: 2px solid #ffce0d;
}

.lines > :nth-child(2) span {
  border: 2px solid #d9d9d9;
}
.lines > :nth-child(3) span {
  border: 2px solid #b36d5e;
}

.lines > :first {
  color: #ffd700;
}
.grey {
  color: #9f9fa4;
}

.leaderboardHeadings > :nth-child(1),
.leaderboardSpecialHeading > :nth-child(1){
  width: clamp(20px, 15vw, 240px);
  margin-right: 65px;
  padding-left: 20px;
}

.leaderboardTotalSpecialHeading > :nth-child(1){
  width: 16vw;
  margin-right: 0;
  padding-left: 20px;
}

.leaderboardHeadings > :nth-child(2){
  display: flex;
  justify-content: center;
  width: clamp(30px, 11vw, 240px);
}

.leaderboardTotalSpecialHeading > :nth-child(2){
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.leaderboardSpecialHeading > :nth-child(2) {
  padding: 0;
  margin: 0;  
}

.leaderboardTotalSpecialHeading > :nth-child(3) {
  margin: 0;
  padding: 0;
}

.specialHeading{
  width:60px;
  text-align: center;
}
.leaderboardHeadings > :nth-child(3) {
  display: flex;
  justify-content: center;
  width: clamp(30px, 10.5vw, 260px);
}

.leaderboardHeadings > :nth-child(4) {
  display: flex;
  justify-content: center;
  width: clamp(30px, 11vw, 230px);
}

.trophy {
  margin-left: -30px;
  padding-right: 25px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

@media screen and (max-width: 2450px) {
  .leaderboardHeadings > :nth-child(3) {
    width: clamp(30px, 11vw, 280px);
  }
}

@media screen and (max-width: 2300px) {
  .leaderboardHeadings > :nth-child(3) {
    width: clamp(30px, 11.5vw, 280px);
  }
}

@media screen and (max-width: 2180px) {
  .leaderboardHeadings > :nth-child(3) {
    width: clamp(30px, 13vw, 280px);
  }
}

@media screen and (max-width: 1570px) {
  .leaderboardHeadings > :nth-child(3) {
    width: clamp(30px, 12.5vw, 380px);
  }
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1) {
    width: clamp(20px, 16vw, 240px);
    margin-right: 65px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 1500px) {
  .leaderboardHeadings > :nth-child(3) {
    width: clamp(30px, 12.8vw, 250px);
  }
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1) {
    width: clamp(20px, 16vw, 240px);
    margin-right: 65px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 1250px) {
  .leaderboardHeadings > :nth-child(3) {
    width: clamp(30px, 12vw, 250px);
  }
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1) {
    width: clamp(20px, 15.5vw, 240px);
    margin-right: 65px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 1040px) {
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1) {
    width: clamp(20px, 15vw, 230px);
    margin-right: 60px;
    padding-left: 20px;
  }

  .leaderboardHeadings > :nth-child(2) {
    width: clamp(30px, 11.8vw, 250px);
  }
}

@media screen and (max-width: 950px) {
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1) {
    width: clamp(20px, 14vw, 230px);
    margin-right: 70px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 830px) {
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1) {
    width: clamp(20px, 15vw, 230px);
    margin-right: 60px;
  }

  .leaderboardHeadings > :nth-child(3) {
    display: flex;
    width: clamp(30px, 11.7vw, 250px);
  }
}

@media screen and (max-width: 768px) {
  .leaderboardHeadings,
  .leaderboardSpecialHeading{
    margin-left: 20px;
  }
  .leaderboardTotalSpecialHeading{
    margin-left: 110px;
  }
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1) {
    width: clamp(20px, 19vw, 230px);
    margin-right: 55px;
    padding-left: 20px;
    display: flex;
    justify-content: right;  
  }
  .leaderboardTotalSpecialHeading > :nth-child(1){
    width: clamp(140px, 26vw, 140px);
    padding: 0;
  }
}

@media screen and (max-width: 650px) {
  .leaderboardHeadings,
  .leaderboardSpecialHeading {
    margin-left: 20px;
  }

  .grey {
    font-size: 12px;
  }
}

@media screen and (max-width: 540px) {
  .leaderboardHeadings,
  .leaderboardSpecialHeading {
    margin-left: 20px;
  }
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1) {
    width: clamp(20px, 19.7vw, 230px);
    margin-right: 55px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 520px) {
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1) {
    width: clamp(20px, 20vw, 230px);
    margin-right: 50px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 500px) {
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1){
    width: clamp(20px, 19vw, 230px);
    margin-right: 58px;
    padding-left: 20px;
  }

  .leaderboardTotalSpecialHeading > :nth-child(2){
    width: 80%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 445px) {
  .leaderboardHeadings > :nth-child(3) {
    width: clamp(30px, 8.5vw, 480px);
  }

  .leaderboardHeadings > :nth-child(4) {
    width: clamp(30px, 13.5vw, 480px);
  }
}

@media screen and (max-width: 425px) {
  .leaderboardHeadings > :nth-child(3) {
    width: clamp(40px, 7.5vw, 480px);
  }

  .leaderboardHeadings > :nth-child(4) {
    width: clamp(30px, 13.5vw, 480px);
  }

  .leaderboardTotalSpecialHeading > :nth-child(2){
    width: 60%;
  }
}

@media screen and (max-width: 415px) {
  .leaderboardHeadings > :nth-child(3) {
    width: clamp(40px, 3.5vw, 480px);
  }

  .leaderboardHeadings > :nth-child(4) {
    width: clamp(30px, 13.5vw, 480px);
  }
}

@media screen and (max-width: 412px) {
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1) {
    margin-right: 48px;
  }
}

@media screen and (max-width: 410px) {
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1) {
    margin-right: 44px;
  }

  .leaderboardTotalSpecialHeading > :nth-child(2){
    width: 50%;
  }
}

@media screen and (max-width: 395px) {
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1){
    width: clamp(65px, 21vw, 240px);
    margin-right: 30px;
  }

  .leaderboardHeadings > :nth-child(3) {
    width: clamp(40px, 6.5vw, 480px);
  }

  .leaderboardHeadings > :nth-child(4) {
    width: clamp(50px, 10.5vw, 480px);
  }
}

@media screen and (max-width: 380px) {
  .leaderboardHeadings > :nth-child(3) {
    width: clamp(40px, 5.5vw, 480px);
  }

  .leaderboardHeadings > :nth-child(4) {
    width: clamp(50px, 11.5vw, 480px);
  }
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1){
    width: clamp(35px, 16vw, 240px);
    margin-right: 50px;
  }
}

@media screen and (max-width: 364px) {
  .leaderboardHeadings > :nth-child(3) {
    width: clamp(55px, 2.5vw, 480px);
  }

  .leaderboardHeadings > :nth-child(4) {
    width: clamp(38px, 11.5vw, 480px);
  }
  .leaderboardHeadings > :nth-child(1),
  .leaderboardSpecialHeading > :nth-child(1) {
    width: clamp(65px, 15vw, 240px);
    margin-right: 30px;
  }

  .leaderboardTotalSpecialHeading > :nth-child(2){
    width: 40%;
  }
}
