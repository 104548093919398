.storeTransactionButton {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 43px;
    border-radius: 40px;

  }

  .storeTransactionButtonText {
    font-size:24px;
  }

  @media screen and (max-width: 768px) {
    
    .storeTransactionButtonText { 
      font-size:14px; /*to be changed */
    }

    .storeTransactionButton {
      height: 18%;
    }
    

    }