.header {
  z-index: 51;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  width: 100%;
  padding: 0 20px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #bababa;
  max-width:1520px;
}

.header > a {
  cursor: default; /* don't remove it even if you see no value */
}

.logo {
  margin-left: 24px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0 30px;

  border-left: 1px solid #bababa; /* Adjust color and thickness as needed */
  border-right: 1px solid #bababa; /* Adjust color and thickness as needed */
  height: 24px; /* Set the desired height */
  margin-right: 12px;
}

.menuRightContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  margin-right: 36px;
}
.loginSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.iconButton {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  margin: 0 15px;
}

.iconButton:hover {
  cursor: pointer !important;
}

.settingMenuButton {
  position: relative;
}
.settingMenu,
.languageMenu {
  position: absolute;
  top: 100%;

  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
}

.settingMenu div,
.languageMenu div {
  padding: 8px;
  cursor: pointer;
}

.settingMenu.show,
.languageMenu.show {
  display: flex;
}

.logo > img:hover,
.iconButton > img:hover {
  cursor: pointer !important;
}
@media screen and (max-width: 650px) {
  .header {
    padding: 0;
    padding-top: 20px;
    height: 80px;
    justify-content: space-between;
  }

  .menuRightContainer {
    margin: 0;
  }

  /* .languageIcon,
  .settingsIcon{
    width: 40px;
  } */

  .buttonContainer {
    padding: 0px;
    margin: 0px;
  }
}
