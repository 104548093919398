.boosterButton {
  position: relative;
  width: 204px;
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 16px;
  border: 1px solid #bababa;
  background: rgba(255, 255, 255, 0.5);
}

.boosterBadge {
  position: absolute;
  top: 10px;
  left: 180px;
}

@media screen and (max-width: 650px) {
  .boosterButton {
    padding-top: 40px;
    margin-bottom: 16px;

    gap: 10px;
    width: 100px !important;
    height: 100px !important;
  }
  .boosterBadge {
    left: 80px;
  }
}
