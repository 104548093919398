.modalBox {
  display: flex;
  flex-direction: column;
  width:70vw;
  min-width: 300px;
  max-width: 450px;
  min-height: 250px;
  border-radius: 16px;
  border: 1px solid #bababa;
  background: #ffffff80;
  backdrop-filter: blur(12px);
  padding: 30px 50px;
  box-sizing: border-box;
}
