.blockedPersonsTemplate {
  position: relative;
  right: 0;
  left: 0;
  top: 90px;
  z-index: 50;
  width: 100%;
  height: 80vh;
  min-width: 330px;
}

.titleWithIcon {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width:330px;
}

.title {
  text-align: center;
  font-size: clamp(32px, 6vw, 40px);
  width:67%;
}

.arrowButton {
  position: absolute;
  left: 8vw;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
}

.noBlockedPersonsTextContainer{
  position: absolute;
  text-align: center;
  width: 95%;
  max-width: 500px;
  top:40%;
  left:50%;
  transform: translate(-50%,-50%);
}

.noBlockedPersonsTextContainer div{
  font-size:clamp(20px,7vw,40px)
}
