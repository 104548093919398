.search {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchButton {
  position: absolute;
  background: none;
  border: none;
  cursor: pointer !important;
  z-index: 5;
  top: 12px;
  margin-left: 24px;
}

.inputContainer {
  position: relative;
}

.input input {
  width: 588px !important;
  height: 48px !important;
  border-radius: 30px;
  border: 1px solid #bababa;
  padding-left: 65px;
  background: rgba(253, 254, 255, 0.5);
}

.input input::placeholder {
  font-size: 20px;
  color: #a1a1a4;
  font-weight: 500;
}

.filteredUserList {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  max-width: 700px;
  width:80%;
  max-height: 400px;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: center;
}

.filteredUserList::-webkit-scrollbar {
  display: none;
}

.filteredUserItem {
  text-align: center;
}

.filteredUserItem img {
  background-color: white;
  border-radius: 50%;
  border: solid #bababa 2px;
  width: clamp(50px, 10vw, 90px);
}

.avatarUsername {
  font-size: clamp(12px, 2.2vw, 20px);
}

@media screen and (max-width: 650px) {
  .input input {
    width: 318px !important;
    height: 32px !important;
    padding-left: 50px;
  }
  .searchButton {
    margin-left: 16px;
    top: 8px;
  }
}
