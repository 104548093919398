.avatarsIcon {
    width: 90%;
    height: auto;
}

.backgroundAvatars {
    width: 90%;
    height: 265px;
    top: 281px;
    left: 152px;
    border-radius: 12px 0px 0px 0px;
    background: #2282E0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 204px;
    height: 390px;
    border:solid 1px #BABABA;
    border-radius: 16px;
    margin: 10px;
    background: #FFFFFF80;
    position: relative;
    box-sizing: border-box;

}

.avatarBuyButton {
    background: linear-gradient(180deg, #2E95FA 0%, #1872CA 100%);
}
.storeItemCardAddOn {
    position: absolute;
    top: 0;
    right: 0;

  }
  
.storeItemCardEnableRedBoarder {
    border: 2px solid rgba(252, 46, 18, 0.8);
    box-sizing: border-box;
}

@media screen and (max-width: 768px) {

    .cardContainer {
        width: 100px;
        height: 200px;
        margin: 8px;
    }

    .backgroundAvatars  {
        width: 85%;
        height: 50%;
        padding: 45% 0;
        box-sizing: border-box;
    }

    .avatarsIcon {
        width: 80%;
    }

    .avatarsAmount {
        font-size: 14px;
    }

    .avatarBuyButton {
        height: 13%;
    }

    .avatarBuyButton > div {
        font-size: 16px;
    } 

  }
