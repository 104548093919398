.createButton {
  margin-top: 20px; /* Adds spacing above the button */
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.AdminPanelPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Full-screen height */
  background: linear-gradient(
    180deg,
    #f5f7fa,
    #c3cfe2
  ); /* Subtle background gradient */
  font-family: "Arial", sans-serif;
  text-align: center;
  margin: 0; /* Remove any default margins */
}

.AdminHeader {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 2rem;
}

.AdminForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px; /* Ensure the form doesn't get too wide */
}

.AdminInput {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.AdminButton {
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.AdminButton:hover {
  background: #0056b3;
}

.AdminButton:active {
  background: #004085;
}
