.BackButton {
  top: 10px;
  left: 10px;
  font-size: 18px;
  font-weight: bold;
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.SpecialQuizCreation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 110px;
}

.inputField {
  width: 300px;
  margin: 10px 0;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.createButton {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.createButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.dateLabel {
  align-self: flex-start;
  margin-left: 10px;
  font-size: 14px;
  color: #555;
  font-weight: bold;
  margin-bottom: 5px;
}

.errorText {
  color: red;
  font-weight: bold;
}

/* Custom styles for the select input field */
.dropDown {
  width: 100%;
  padding: 12px 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.dropDown:focus {
  border-color: #5b6e83; /* Border color when focused */
  box-shadow: 0 0 8px rgba(91, 110, 131, 0.4); /* Light shadow to highlight focus */
}

.dropDown option {
  font-size: 16px;
  padding: 8px 16px;
  background-color: #fff;
  color: #333;
}

/* Adding a custom dropdown arrow */
.dropDown::after {
  content: "▼"; /* Unicode for a downwards arrow */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 18px;
  color: #333;
}

/* Styling the container for positioning */
.selectContainer {
  position: relative;
  display: inline-block;
  width: 100%;
}

.colorpicker {
  margin-bottom: 50px;
}
