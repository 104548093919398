.BackButton {
  top: 10px;
  left: 10px; /* Move it to the left side */
  font-size: 18px;
  font-weight: bold;
  padding: 12px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.SpecialQuizView {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tournamentButton {
  /* Add your default button styles here */
  background-color: #4caf50; /* Green color for valid tournaments */
  color: white;
  border: none;
  padding: 10px;
  margin: 2px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.tournamentButton:hover {
  background-color: #45a049; /* Slightly darker green for hover effect */
}

.redButton {
  background-color: red; /* Red color for tournaments with no questions */
  color: white;
}

.redButton:hover {
  background-color: darkred; /* Darker red on hover */
}
