.notificationsContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  text-align: center;
  margin-bottom: 20px;
  font-size: clamp(32px, 7vw, 50px);
}

.walletBalance {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.buttonsContainer {
  display: flex;
  justify-content: center
  
}

.walletButton {
  overflow: hidden;
  white-space: normal;
  margin: 10px;
  
}

.walletButtonDisabled {
  opacity: 0.6;
  /* pointer-events: none; */
}

@media screen and (max-width: 650px) {
  .notificationsContainer {
    margin: 0px auto 16px;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    width: 200px;
  }

  .buttonsContainer {
    display: flex;
    gap: 0px;
  }

}
