.notificationContent {
  width: 600px;
  height: clamp(100px, calc(100px + ((100vw - 460px) * (20 / 200))), 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  background: linear-gradient(180deg, #0075ff 0%, #005dca 100%);
}

.notificationHeadText {
  font-size: clamp(30px, calc(30px + ((100vw - 460px) * (6 / 200))), 36px);
}

.notificationCaptionText {
  width: 100%;
  opacity: 0.4;
  font-size: clamp(12px, calc(12px + ((100vw - 460px) * (12 / 200))), 24px);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 650px) {
  .notificationContent {
    text-wrap: wrap;
    width: 100%;
  }
  .notificationCaptionText {
    width: 100%;
    margin-left: 0;
  }
}
