.box-container {
  width: 420px;
  height: 325px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #bababa;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
  padding: 10px 16px 12px;
  text-align: center;
}

.box-container > button {
  width: 93%;
}

.text-container {
  letter-spacing: -0.3px;
  line-height: 160%;
}
.header-text {
  color: #0075ff;
  font-size: 24px;
}

.caption-text {
  font-size: 16px;
}

.notifications-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 146px;
  width: 100%;
}

@media (max-width: 660px) {
  .box-container {
    width: 330px;
    height: 285px;
  }
  .box-container button {
    height: 60px;
  }

  .box-container > button div {
    font-size: 24px;
  }
  .notifications-container {
    height: 130px;
  }
}
