.modalContainerBackground {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(32, 18, 2, 0);
  z-index: 60;
}

.modalContainer {
  width: fit-content;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.modalImage {
  position: relative;
  top: clamp(25px, 7vw, 40px);
  z-index: -1;
  width: clamp(130px, 30vw, 180px);
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-grow: 1;
  gap: 30px;
}

.modalHeading {
  color: #0075ff;
}

.textSection {
  padding: 0 10px;
}

.buttonsSection {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.okButton {
  width: 100%;
}
