.congratulations-template {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    min-height: 100vh;
    margin: 0 auto;
  }
  
  .congratulationsBox {
    margin-top: 270px;
  }
  
  @keyframes shake {
    0% { transform: translateX(0) }
    25% { transform: translateX(15px) }
    50% { transform: translateX(0px) }
    75% { transform: translateX(15px) }
    100% { transform: translateX(0) }
  }
  
  .shake {
    animation: shake 5s ease; 
  }
  
  @media (max-width: 650px) {
    .congratulations-template {
      position: absolute;
      min-height: unset;
      height: fit-content;
      padding-top: 100px;
      transform: translate(-50%, 50%);
      bottom: 50%;
      left: 50%;
    }
  
    .congratulationsBox {
      margin-top: 185px;
    }
  }
  