.inventoryItem {
  display: flex;
  align-items: center;
  width: 205px;
  height: 80px;
  top: 514px;
  left: 143px;
  background: #ffffff80;
  border: 1px solid #bababa;
  border-radius: 16px;
}
.grey {
  color: rgba(0, 0, 0, 0.4);
}
