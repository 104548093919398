.storeAvatarGroupContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}

.storeAvatarGroupCards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

@media screen and (max-width: 768px) {

.cardsTitles {
    font-size: 24px;
}

}