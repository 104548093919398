.chattingPersonWrapper,
.chattingPersonWrapperActive {
  width: 100%;
  padding: 6px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border: 1px solid #bababa;
  border-radius: 10px;
  transform: skewX(-20deg);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
  box-sizing: border-box;
}

.chattingPersonWrapperActive {
  border: 1px solid rgba(255, 0, 0, 0);
  background: linear-gradient(180deg, rgba(46, 149, 250, 0.7) 0%, rgba(24, 114, 202, 0.7) 100%);
}

.chattingPersonInfos {
  padding-left: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  transform: skewX(20deg);
  white-space: nowrap;
}

.chattingPersonInfos img {
  display: flex;
  border-radius: 50%;
  background-color: white;
  width: clamp(38px, calc(48px - 0.2 * (960px - 100vw)), 48px);
}

.userNameText {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: clamp(100px, calc(192px - 0.0895 * (1920px - 100vw)), 192px);
  font-size: clamp(14px, 1.6vw, 18px);
}

.messageText {
  color: #717380;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: clamp(126px, calc(206px - 0.7 * (1600px - 100vw)), 206px);
  font-size: clamp(10px, 1.1vw, 13px);
}
.chattingPersonWrapperActive .messageText,
.chattingPersonWrapperActive .userNameText {
  color: #f5f6ff;
}

.timestamp {
  position: absolute;
  right: 0;
  margin: 5px 10px 0 0;
  transform: skewX(20deg);
  color: #bababa;
  font-size: clamp(9px, calc(13px - 0.0225 * (1120px - 100vw)), 13px);
}

.chattingPersonWrapperActive .timestamp {
  color: #f5f7ff;
}

@media screen and (max-width: 768px){
  .chattingPersonWrapper {
    padding-left: 10px;
  }

  .chattingPersonWrapperActive {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
    padding-left: 10px;
    border: 1px solid #bababa;
  }

  .chattingPersonInfos img {
    width: 48px;
  }

  .userNameText {
    font-size: 18px;
    color: black !important;
    max-width: 192px;
  }

  .messageText {
    color: #717380 !important;
    font-size: 14px;
    max-width: 206px;
  }

  .timestamp {
    color: #bababa !important;
    font-size: 14px;
  }
}
