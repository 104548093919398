.playerInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-left: 30px;
  padding-right: 5px;
}

.playerInfo:hover {
  cursor: pointer;
}

.playerInfo img {
  pointer-events: none;
}

.avatarInfo {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}
