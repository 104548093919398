.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-width: 432px;
  height: 320px;
  box-sizing: border-box;
  border-radius: 28px;
  margin-right: 25px ;
  padding: 30px;
  transform: translateX(400px); /* Off-screen position so wont be visible in screen before the animation starts */
}

.quizDescription {
  position: absolute;
  top: 50%;
  z-index: 1;
  display: flex;
  align-items: baseline;
  width: 90%;
  justify-content: space-between;
  box-sizing: border-box;
  margin-top: 15px;
}

.quizName {
  position: absolute;
  top: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 70%;
  height: 65px;
  justify-content: space-between;
  box-sizing: border-box;
  text-shadow: 1px 2px 4px rgb(0, 0, 0);
}

.quizDateContainer{
  position: relative;
  bottom: 105px;
  right: 15px;
  text-align: start;
  padding: 3px 8px;
  border-radius: 20px;
  background-color: rgba(60, 45, 45, 0.406);
  width: fit-content;
  box-sizing: border-box;
}

.quizDate{
  width: fit-content;
  color: #ffffff;
  z-index: 1;
}
  

.icon {
  z-index: -1;
  margin-left: 200px;
  position: absolute;
  width: 100%;
  top: -8%;
  right: -0%;
}

/* Buttons' colors START */

.greyButton {
  background: linear-gradient(
    180deg,
    rgba(125, 125, 125, 0.3) 0%,
    rgba(41, 40, 40, 0.3) 100%
  );
  font-weight: 700;
  font-size: 16px;
  color: black;
}

.goldButton {
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
  font-weight: 700;
  font-size: 16px;
  color: black;
}

.registerGoldButton {
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
  width: 100%;
  height: 56px;
  border-radius: 16px;
  border: 0px;
}

.greenButton {
  background: linear-gradient(180deg, #57DE2E 0%, #40A223 100%);
  color: white;
  font-weight: 700;
  font-size: 16px;
}

/* Buttons' colors END */



@media screen and (max-width: 768px) {
  .cardContainer {
    min-width: 221px;
    height: 175px;
    padding: 10px;
    border-radius: 16px;
  }

  .cardBottom {
    margin-top: 55px;
  }

  .quizDate{
    max-width: 50px;
  }

  .quizDateContainer{
    bottom: 20px;
    right: 3px;
    padding: 3px 7px;
    border-radius: 10px;
  }

  .quizName {
    margin-top: -10px;
  }

  .quizDescription {
    margin-top: 10px;

  }

  .registerGoldButton {
    font-size: 10px;
    height: 28px;
    border-radius: 8px;
  }

  .greyButton {
    font-size: 10px;
  }

  .greenButton {
    font-size: 10px;
  }

  .cardBottom {
    top: 120px;
    height: 20%;
  }

  .goldButton {
    font-size: 10px;
  }

}
