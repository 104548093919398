.wheel-base {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid;
}

.leds {
  position: absolute;
  background: radial-gradient(50.29% 50.29% at 49.91% 49.38%, #ffffbb 0.54%, #e1b141 100%);
  box-shadow: 0px 1px 7px 0px #0000008c;
}
