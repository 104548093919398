.avatar {
  position: absolute;
  border: 4px solid #0075ff;
  border-radius: 50%;
  box-shadow: 0px 0px 13px #0075ff;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}


