.avatarToEdit {
  margin: 0 auto;
  height: fit-content;
  background-color: white;
  border-radius: 50%;
  width: clamp(80px, 20vw, 110px);
}

.avatarContainer {
  position: relative; /* Establish a positioning context for the check icon */
  display: inline-block; /* Keep the avatars inline and block for layout */
  margin: 10px; /* Add some space around each avatar */
  width:fit-content;
  height: fit-content;
}

.selectedAvatarToEdit {
  box-shadow: 0 0 0 3px #bac3fd; /* Change box-shadow color to create the border effect */
  margin: 0 auto;
  background-color: white;
  height: fit-content;
  border-radius: 50%;
  width: clamp(80px, 20vw, 110px);
}

.checkIconBackground {
  display: flex;
  background-color: white;
  position: absolute;
  bottom: 15px;
  right: 0px;
  border-radius: 50%;
}

.checkIcon {
  width: clamp(24px,6vw, 32px);
}
