.iframe {
  border: none;
  display: flex;
  width: 70%;
  max-width: 400px;
  min-width: 300px;
  background-color: aliceblue;
  border-radius: 2rem;
  padding: 20px;
  height: calc(70% - 20px);
  max-height: 350px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

.iframeContainer {
  width: 100%;
  height: 100vh;
  max-height: 1024px;
}

@media screen and (min-height: 1150px) and (min-width: 1000px) {
  .iframe {
    margin-top: 100px;
    min-height: 1000px;
    min-width: 800px;
  }
}

@media screen and (max-height: 620px) {
  .iframe {
    top: 55%;
    height: calc(60% + 20px);
  }
}

@media screen and (max-height: 450px) {
  .iframe {
    top: 60%;
    height: calc(60% + 20px);
  }
}

@media screen and (max-height: 368px) {
  .iframe {
    top: 65%;
    height: calc(60% + 20px);
  }
}

@media screen and (max-height: 575px) and (max-width:755px){
  .iframe {
    top: 50%;
    height: calc(30% + 20px);
  }
}