.timerContainer {
  width: 100%;
  height: 20px;
  padding: 4px;
  border-radius: 20px;
  border: 1px solid rgb(0, 0, 0, 0.1);
}

.timerTrack {
  width: 100%;
  height: 100%;
  background-color: rgb(255, 202, 13, 0.2);
  border-radius: 20px;
}

.timerBar {
  height: 100%;
  width: 100%;
  background-color: #ffca0d;
  border-radius: 20px;
  animation-name: fillBar;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes fillBar {
  to {
    width: 0%; /* The bar will animate to fill up to 100% of the track's width */
  }
}
