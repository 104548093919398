.panel {
  display: flex;
  flex-direction: column;
  position: relative;
}

.panel2 {
  display: flex;
  flex-direction: column;
  position: relative;
}

.icon {
  align-self: flex-end;
  top: -10px;
  position: absolute;
  pointer-events: none;
}

.grey {
  background: linear-gradient(
    180deg,
    rgba(125, 125, 125, 0.3) 0%,
    rgba(41, 40, 40, 0.3) 100%
  );
}
.red {
  background: linear-gradient(
    180deg,
    rgba(255, 0, 0, 0.8) 0%,
    rgba(139, 1, 1, 0.8) 100%
  );
}

.blue {
  background: linear-gradient(
    180deg,
    rgba(0, 240, 255, 0.8) 0%,
    rgba(18, 140, 252, 0.8) 100%
  );
}

.darkBlue {
  background: var(--blue, linear-gradient(180deg, #2e95fa 0%, #1872ca 100%));
}

.green {
  background: linear-gradient(
    180deg,
    rgba(147, 240, 91, 0.8) 0%,
    rgba(55, 151, 22, 0.8) 100%
  );
}

.lightGreen {
  background: linear-gradient(
    180deg,
    rgba(240, 207, 91, 0.8) 0%,
    rgba(48, 151, 22, 0.8) 100%
  );
}

.orange {
  background: linear-gradient(
    180deg,
    rgba(241, 201, 97, 0.8) 0%,
    rgba(246, 80, 27, 0.8) 100%
  );
}

.purple {
  background: linear-gradient(
    180deg,
    rgba(132, 55, 210, 0.8) 0%,
    rgba(90, 29, 188, 0.8) 100%
  );
}

.magenta {
  background: linear-gradient(
    180deg,
    rgba(210, 91, 240, 0.8) 0%,
    rgba(255, 73, 248, 0.8) 0.01%,
    rgba(252, 21, 201, 0.8) 100%
  );
}
.lightOrange {
  background: linear-gradient(
    180deg,
    rgba(255, 175, 6, 0.8) 0%,
    rgba(255, 125, 6, 0.8) 100%
  );
}

.darkOrange {
  background: linear-gradient(
    180deg,
    rgba(255, 92, 0, 0.8) 0%,
    rgba(252, 46, 18, 0.8) 100%
  );
}

.content {
  width: 200px;
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.content img{
  width:146px;
}

.content2 {
  width: 316px;
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.textContainer {
  z-index: 1;
  display: flex;
  margin-top: -30px;
  flex-direction: column;
  align-self: flex-start;

  margin-left: 12px;
  pointer-events: none;
}
.textContainerSubtitle {
  position: absolute;
  bottom: 30px;
}

.rectangle {
  border: 1px solid rgba(255, 255, 255, 0.54);
  z-index: -1;
  width: 100%;
  height: 180px; /* Adjust height as needed */
  position: absolute;
  bottom: 0;
  border-radius: 24px;
}

.tournamentBanner {
  z-index: 1;
  width: 100%;
  display: flex;
  position: fixed;
}

.tournamentBannerContent {
  position: fixed;
  bottom: 70px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.bannerRectangle {
  border: 1px solid rgba(255, 255, 255, 0.54);
  z-index: -1;
  width: 100%;
  height: 160px; /* Adjust height as needed */
  position: fixed;
  bottom: 0;
  border-radius: 24px;
}

.bannerRectangle:after {
  z-index: 10;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent, #727295);
}

.tournamentBannerTextContainer {
  margin-top: 40px;
  margin-left: 20px;
}

@media screen and (max-width: 760px) {
  .panel {
    overflow: hidden;
  }
  .content {
    width: 100px;
    height: 115px;
  }
  .rectangle {
    height: 98px; /* Adjust height as needed */
  }
  .textContainer {
    margin-top: -10px;
    margin-left: 12px;
    pointer-events: none;
  }
  .content img{
    width:70px;
  }
}
