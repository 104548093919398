.loginIconsContainer {
  display: flex;
  gap: 20px;
  justify-content: center;
  height: 65px; 
}

.appleButton img{
  position: relative;
  bottom:2px;
  pointer-events: none;
}

.googleButton, .appleButton {
  width: 100%;
  background: #fff;
  border: none;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
}

.googleButton:hover, .appleButton:hover{
  cursor: pointer;
}

.googleButton > img {
  pointer-events: none; /* This ensures the click events pass through to the button */
}
