.cardContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 432px;
  height: 335px;
  box-sizing: border-box;
  margin: 0px 19px 0px 5px;
  transform: translateX(400px); /* Off-screen position so wont be visible in screen before the animation starts */
}

.getTicket {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
  align-items: center;
  left: 3%;
  top: 3%;
  width: 113px;
  height: 24px;
  border-radius: 34px;
  background: linear-gradient(180deg, #FFCE0D 0%, #FFAF06 100%);
  z-index: 1;

}

.getTicket div {
  font-size: 12px;
}

.textQuestionsPrize {
  width: 189px;
  height: 48px;
  margin-top: 10%;
  margin-left: 5%;
  color: #ffffff78; 
  z-index: 1;

}

.icon {
  z-index: 1;
  margin-left: 200px;
  position: absolute;
  width: 58%;
  top: -16%;
  right: -6%;
}

/* Buttons' colors START */

.greyButton {
  background: linear-gradient(
    180deg,
    rgba(125, 125, 125, 0.3) 0%,
    rgba(41, 40, 40, 0.3) 100%
  );
  font-weight: 700;
  font-size: 16px;
  color: black;
}

.goldButton {
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
  font-weight: 700;
  font-size: 16px;
  color: black;

}

.registerGoldButton {
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
}

.greenButton {
  background: linear-gradient(180deg, #57DE2E 0%, #40A223 100%);
  color: white;
  font-weight: 700;
  font-size: 16px;
}

/* Buttons' colors END */

/* Backgrounds' Colors START */

.blueBackground {
  background: linear-gradient(180deg, #255fe1 60%, #133072 99%);
}

.lightPurpleBackground {
  background: linear-gradient(180deg, #C8B9FF 60%, #655d80 99%);
}

.brownBackground {
  background: linear-gradient(180deg, #c27e57 60%, #62402c 99%);
}

.darkPurpleBackground {
  background: linear-gradient(180deg, #944c95 60%, #4a264b 99%);
}

.greenBackground {
  background: linear-gradient(180deg, #69ac4a 60%, #355726 99%);
}

.redBackground {
  background: linear-gradient(180deg, #ff6262 60%, #803232 99%);
}

/* Backgrounds' Colors END */


.dateContainer {
  position: absolute;
  top: 43%;
  z-index: 1;
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}

.date{
  display: flex;
  align-items: end;
}

.dateHourText{
  font-size: 25px;
}

.timeZoneText{
  margin-left: 1px;
  padding-bottom: 2px;
}

.cardTop {
  border: 1px solid rgba(255, 255, 255, 0.54);
  width: 100%;
  height: 56%;
  position: absolute;
  border-radius: 24px 24px 0px 0px;
  box-sizing: border-box;

}

.cardBottom {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 186px;
  height: 45%;
  padding-top: 16px;
  padding-bottom: 12px;
  gap: 16px;
  width: 100%;
  border-radius: 0 0 28px 28px;
  border: 1px solid #bababa;
  background-color: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  justify-content: center;

}

.cardBottom span {
  text-align: center;
  
}

.cardBottomInfo button {
  border: none;
  text-wrap: nowrap;
  border-radius: 34px;
  width: 100px;
  height: 32px;
}

.cardBottomInfo {
  display: flex;
  align-items: center;
  justify-content: space-around;    
}

.cardBottomButton {
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 6px;
  
}

.cardBottomButton button {
  border: none;
  border-radius: 16px;
  width: 93%;
  height: 56px;
  
}

.enableGoldBoarder {
  box-shadow: 0 0 0 5px #ffc23f;
  border-radius: 26px;
  box-sizing: border-box; 

  
}

@media screen and (max-width: 768px) {
  .cardContainer {
    min-width: 221px;
    height: 224px;
  }

  .getTicket {
    width: 72px;
    height: 16px;
    left: 5%;
    top: 3%;
  }

  .getTicket div {
    font-size: 8px;
  }

  .textQuestionsPrize {
    width: 104px;
    height: 27px;
    margin-top: 14%;
  }  

  .textQuestionsPrize div {
    font-size: 8px;
  } 

  .cardTop {
    height: 121px;
  }

  .icon {
    width: 70%;
    top: -11%;
    right: -10%;
  }

  .cardBottomButton button {
    border-radius: 16px;
    height: 37px;
  }

  .cardBottomInfo button {
    width: 51px;
    height: 21px;
  }

  .registerGoldButton {
    font-size: 10px;
  }

  .greyButton {
    font-size: 10px;
  }

  .greenButton {
    font-size: 10px;
  }

  .cardBottom {
    top: 120px;
    height: 47%;
  }

  .goldButton {
    font-size: 10px;
  }

  .dateContainer {
    padding: 0 5px 0 10px;
  }

  .weekDay{
    font-size:16px;
  }

  .dateHourText {
    font-size: 16px;
    text-wrap: nowrap;  
  }

  .timeZoneText{
    font-size: 10px;
    padding-bottom: 1.5px;
  }

  .enableGoldBoarder {
    box-shadow: 0 0 0 3px #ffc23f;
  }

}
