.mainPageTemplate {
  overflow-x: hidden;
  margin-bottom: 130px;
  min-width:370px;
}

.gameSelectionSectionLoggedIn,
.gameSelectionSectionLoggedOut {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 130px;
  position: relative;
  top:-20px;
}

.gameSelectionSectionLoggedOut {
  margin-left: 20px;
}

.title {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.title div {
  text-wrap: nowrap;
  font-size: clamp(24px, calc(50px - (860px - 100vw) * 0.051), 50px);
}

.title button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.title button > img {
  cursor: pointer !important;
}

.line {
  height: 1px;
  margin: 20px 0 40px 0;
  opacity: 0.2;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000 50%, rgba(255, 255, 255, 0) 100%, rgba(0, 0, 0, 0) 100%);
}

.text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.cashPanels {
  margin-left: 120px;
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
  overflow-x: auto;
}

.spinner {
  display: flex;
  z-index: 5;
  position: fixed;
  bottom: 50px;
  right: 25px;
}

.spinner button {
  border: none;
  border-radius: 50%;
  background: none;
  cursor: pointer;
}

@media screen and (max-width: 768px){
  .gameSelectionSectionLoggedIn,
  .gameSelectionSectionLoggedOut {
    margin-left: 0px;
  }

  .title {
    margin-left: 13px;
  }

  .title img {
    width: clamp(24px, calc(48px - ((860px - 100vw) * 0.05217)), 48px);
  }

  /* arrows of carousel */
  .title button {
    display: none;
  }

  .cashPanels {
    margin: 0;
  }
}
