.panel {
  display: flex;
  flex-direction: column;
  position: relative;
  width: clamp(222px, calc(222px + ((100vw - 860px) * 0.344)), 432px);
  height: clamp(242px, calc(242px + ((100vw - 860px) * 0.1606)), 340px);
  flex: 0 0 auto;
}

.badge {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
  align-items: center;
  right: 4%;
  top: 3%;
  z-index: 5;
  width: clamp(71px, calc(71px + ((100vw - 860px) * 0.0688)), 113px);
  height: clamp(15px, calc(15px + ((100vw - 860px) * 0.0147)), 24px);
  border-radius: 34px;
  background: #48bd46;
}

.badge div {
  font-size: clamp(8px, calc(8px + ((100vw - 860px) * 0.006)), 12px);
}

.iconGroup {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  left: 0%;
  gap: 30px;
}

.icon1 {
  z-index: 1;
  align-self: flex-end;
  top: clamp(-2px, calc(-10px + ((100vw - 860px) * 0.0131)), -10px);
  left: 0%;
  position: absolute;
  pointer-events: none;
  width: clamp(116px, calc(116px + ((100vw - 860px) * 0.1049)), 180px);
}

.icon2 {
  z-index: 2;
  align-self: flex-end;
  top: 4%;
  left: clamp(22%, 13.4vw, 27%);
  position: absolute;
  pointer-events: none;
  width: clamp(98px, calc(98px + ((100vw - 860px) * 0.0885)), 152px);
}

.icon3 {
  z-index: 1;
  align-self: flex-end;
  top: 2%;
  left: clamp(46%, 26vw, 49.5%);
  position: absolute;
  pointer-events: none;
  width: clamp(81px, calc(81px + ((100vw - 860px) * 0.0721)), 125px);
}

.grey {
  background: linear-gradient(
    180deg,
    rgba(125, 125, 125, 0.3) 0%,
    rgba(41, 40, 40, 0.3) 100%
  );
}

.darkGrey {
  background: linear-gradient(180deg, #565656 0%, #252424 100%);
  backdrop-filter: blur(12px);
}
.red {
  background: linear-gradient(
    180deg,
    rgba(255, 0, 0, 0.8) 0%,
    rgba(139, 1, 1, 0.8) 100%
  );
}

.blue {
  background: linear-gradient(
    180deg,
    rgba(0, 240, 255, 0.8) 0%,
    rgba(18, 140, 252, 0.8) 100%
  );
}

.darkBlue {
  background: var(--blue, linear-gradient(180deg, #2e95fa 0%, #1872ca 100%));
}

.green {
  background: linear-gradient(
    180deg,
    rgba(147, 240, 91, 0.8) 0%,
    rgba(55, 151, 22, 0.8) 100%
  );
}

.darkGreen {
  background: linear-gradient(180deg, #57de2e 0%, #40a223 100%);
}

.lightGreen {
  background: linear-gradient(
    180deg,
    rgba(240, 207, 91, 0.8) 0%,
    rgba(48, 151, 22, 0.8) 100%
  );
}

.orange {
  background: linear-gradient(
    180deg,
    rgba(241, 201, 97, 0.8) 0%,
    rgba(246, 80, 27, 0.8) 100%
  );
}

.purple {
  background: linear-gradient(
    180deg,
    rgba(132, 55, 210, 0.8) 0%,
    rgba(90, 29, 188, 0.8) 100%
  );
}

.magenta {
  background: linear-gradient(
    180deg,
    rgba(210, 91, 240, 0.8) 0%,
    rgba(255, 73, 248, 0.8) 0.01%,
    rgba(252, 21, 201, 0.8) 100%
  );
}
.lightOrange {
  background: linear-gradient(
    180deg,
    rgba(255, 175, 6, 0.8) 0%,
    rgba(255, 125, 6, 0.8) 100%
  );
}

.darkOrange {
  background: linear-gradient(
    180deg,
    rgba(255, 92, 0, 0.8) 0%,
    rgba(252, 46, 18, 0.8) 100%
  );
}

.gold {
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
}

.content {
  width: 100%;
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
}

.textContainer {
  z-index: 1;
  position: absolute;
  top: clamp(38%, 10.7vw, 40%);
  left: 5%;
  pointer-events: none;
}

.textContainer div {
  font-size: clamp(16px, calc(16px + ((100vw - 860px) * 0.0229)), 30px);
}

.rectangle {
  border: 1px solid rgba(255, 255, 255, 0.54);
  z-index: 0;
  width: 100%;
  height: clamp(116px, calc(116px + ((100vw - 860px) * 0.1049)), 180px);
  position: absolute;
  border-radius: 24px 24px 0px 0px;
}

.panelInfo {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: clamp(117px, calc(117px + ((100vw - 860px) * 0.1049)), 181px);
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 16px;
  width: 100%;
  z-index: -1;
  border-radius: 0 0 24px 24px;
  border: 1px solid #bababa;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
}

.panelInfo span {
  text-align: center;
}
.upperSection button {
  border: none;
  text-wrap: nowrap;
  border-radius: 34px;
  width: clamp(51px, calc(51px + ((100vw - 860px) * 0.0803)), 100px);
  height: clamp(20px, calc(20px + ((100vw - 860px) * 0.0196)), 32px);
}

.upperSection {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.upperSection > span:nth-of-type(1) div,
.upperSection > span:nth-of-type(3) div {
  font-size: clamp(10px, calc(10px + ((100vw - 860px) * 0.012)), 16px);
}

/* text 'starts in "time"' */
.upperSection > span:nth-of-type(2) {
  text-wrap: nowrap;
}

.upperSection > span:nth-of-type(2) > div:nth-of-type(1) {
  font-size: clamp(16px, calc(16px + ((100vw - 860px) * 0.0131)), 24px);
}

.upperSection > span:nth-of-type(2) > div:nth-of-type(2) {
  font-size: clamp(10px, calc(10px + ((100vw - 860px) * 0.0098)), 16px);
}

.bottomSection {
  display: flex;
  justify-content: center;
  position: relative;
}

.bottomSection button {
  border: none;
  border-radius: 16px;
  width: 93%;
  height: clamp(36px, calc(36px + ((100vw - 860px) * 0.0327)), 56px);
}

@media screen and (max-width: 768px) {
  .icon1 {
    z-index: 1;
    align-self: flex-end;
    top: -2%;
    left: -5%;
    position: absolute;
    pointer-events: none;
  }
  .icon2 {
    left: 22%;
  }
  .icon3 {
    left: 46%;
  }
}
