.quizCardsWrapperLoggedIn,
.quizCardsWrapperLoggedOut {
  display: flex;
  justify-content: flex-start;
  gap: 25px;
  width: calc(100vw - 110px);
  min-width:370px;
  overflow-x: auto;
  padding-top: 30px;
}

.quizCardsWrapperLoggedOut {
  width: calc(100vw - 13px);
}

@media screen and (max-width: 768px){
  .quizCardsWrapperLoggedIn,
  .quizCardsWrapperLoggedOut {
    margin-left: 13px;
    padding-top: 25px;
    width: calc(100vw - 13px);
  }
}

/* Add this to your CSS file */

@keyframes bounceIn {
  0% {
    transform: translateY(-300px); /* Start off-screen above */
    opacity: 1; /* Start hidden */
  }
  20% {
    opacity: 1; /* Fade in */
    transform: translateY(0px); /* Move to original position */
  }
  40% {
    transform: translateY(-60px); /* Move up for the first bounce */
  }
  60% {
    transform: translateY(0px); /* Move down slightly */
  }
  80% {
    transform: translateY(-20px); /* A little bounce up */
  }
  100% {
    transform: translateY(0); /* Settle back to original position */
  }
}

.bounceIn {
  animation: bounceIn 0.6s ease forwards; /* Keep duration for a snappier effect */
}
.hidden {
  opacity: 0; /* Fully transparent */
  transition: opacity 0.3s ease; /* Smooth transition to visibility */
}