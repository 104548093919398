.chatWindowAndTypingSectionWrapper {
  border: solid #bababa;
  border-radius: clamp(20px, 1.9vw, 30px);
  overflow: hidden;
}

@media screen and (max-width: 768px){
  .chatWindowAndTypingSectionWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0px;
    height: calc(100% - 170px);
    border: none;
    border-top: solid #bababa;
  }
}
