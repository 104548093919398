.statisticsInfoBox {
  /* border: solid; */
  width: 100px;
  text-align: center;
  letter-spacing: -0.3px;
}

.numberOfResult {
  background: linear-gradient(180deg, #2e95fa 0%, #1872ca 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 43px;
}

@media (max-width: 650px) {
  .statisticsInfoBox {
    width: 80px;
  }

  .numberOfResult {
    font-size: 30px;
    height: 33px;
  }

  .textOfResult {
    font-size: 12px;
  }
}
