.storeContainerEntire {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 130px;
  margin-left: 100px;
}

.storeContainerDesktopEntire {
  display: flex;
}

.storeContainerMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resetButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #2e95fa 0%, #1872ca 100%);
  width: clamp(343px, 30vw, 422px);
  height: 54px;
  border-radius: 16px;
  border: none;
  margin-top: 70px;
  margin-bottom: 150px;
}

.guaranteeText1{
  text-align: center;
  /* max-width: 700px; */
  margin-top: 20px;
  font-size: 27px;
}

.guaranteeText2{
  text-align: center;
  max-width: 700px;
  padding: 0 80px;
  margin-top: 5px;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .storeContainerEntire {
    flex-wrap: wrap;
    margin-left: 0px;
  }

  .storeTitle {
    font-size: 35px;
  }
}
