.storeTemplate {
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-left: 100px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1.5fr 1fr;

  grid-auto-flow: row dense;
  grid-template-areas:
    "tickets spins"
    "specialPackages avatars"
    "coins crowns";
  gap: 24px;
  text-align: center;
}

.specialPackages {
  grid-area: specialPackages;
}

.avatars {
  grid-area: avatars;
}

.tickets {
  grid-area: tickets;
}
.ticketCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 700px;
  justify-content: space-evenly;
  gap:20px;
}

.spins {
  grid-area: spins;
}

.coins {
  grid-area: coins;
}

.crowns {
  grid-area: crowns;
}

@media screen and (max-width: 768px){
  .storeTemplate {
    margin:0;
  }
}

@media screen and (max-width: 650px) {
  .storeTemplate {
    margin-bottom: 100px;
    -webkit-margin-top-collapse: 24px;
    overflow-x: hidden;
  }
  .container {
    grid-template-columns: 1fr; /* Change to a single column layout */
    grid-template-rows: auto; /* Reset rows to auto */
    grid-template-areas:
      "tickets"
      "spins"
      "specialPackages"
      "avatars"
      "coins"
      "crowns";
  }
}
