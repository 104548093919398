.congratulationsBoxTemplate {
  position: relative;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 300px;
}

.congratulationsBox {
  position: absolute;
  height: fit-content;
}

.congratulationsBox > img {
  position: relative;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.congratulationsBoxPlayers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid #bababa;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(100px);
  padding: 0 16px 12px;
  box-sizing: border-box;
  margin:0 auto;
  max-width: 400px;
}

.congratulationsTextbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.blueColor {
  color: #0075ff;
}

