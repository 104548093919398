.wheel-base {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leds {
  position: absolute;
}

/* targeting the arrow  */
.wheel-base > img {
  position: relative;
  z-index: 1;
  bottom: 50%;
  transform: translateY(18%);
}
