.quizSelectionSection {
  /* overflow-x: hidden; */
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  align-items: center;
  justify-items: center;
  margin-left: 100px;
  /* margin-top:200px; */
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 0;
  box-sizing: border-box;
}
.title button {
  background: none;
  border: none;
}

.line {
  height: 1px;
  margin: 40px;
  opacity: 0.2;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    #000 50%,
    rgba(255, 255, 255, 0) 100%,
    rgba(0, 0, 0, 0) 100%
  );
}

.text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-left: 40px;
}

.buttonArea {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 244px;
  justify-content: center;

  margin-top: 50px;
}

.playNowDeactivated {
  background: rgba(76, 69, 64, 0.45);
  pointer-events: none;
}

.logged-in .buttonArea :nth-child(2) :nth-child(1) :nth-child(1)::before {
  content: url("../../../assets/images/other_icons/addUser.png");
  display: block;
  position: relative;
}

.categorySelectionContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  max-width: 1600px;
  justify-content: center;
}

.infoHeading {
  font-size: clamp(25px, 4vw, 50px);
  margin-bottom: 20px;
}

@media screen and (max-width: 760px) {
  .quizSelectionSection {
    margin-left: 0px;
    max-width: 95%;
    min-width: 340px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 650px) {
  .quizSelectionSection {
    overflow: hidden;
    margin-bottom: 190px;
  }

  .quizSelectionSectionCategories {
    max-width: 450px;
  }

  .buttonArea {
    z-index: 1;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  .categorySelectionContainer {
    gap: 20px;
  }
}
