.typography {
  /* Common styles for typography atom */
}

/* Variant-specific styles */
.headingXL {
  font-size: 60px;
}

.headingL {
  font-size: 50px;
}
.heading1 {
  font-size: 40px;
}

.heading2 {
  font-size: 30px;
}

.heading3 {
  font-size: 20px;
}

.body1 {
  font-size: 18px;
}

.body2 {
  font-size: 16px;
}

.caption {
  font-size: 13px;
}

.small {
  font-size: 10px;
}

/* Italian style */
.italian {
  font-style: italic;
}

/* Font weights */
.font900 {
  font-weight: 900;
}

.font700 {
  font-weight: 700;
}

.font500 {
  font-weight: 500;
}

.font400 {
  font-weight: 400;
}

/* Text color */

.colorWhite {
  color: white;
}
.colorBlack {
  color: black;
}

.colorRed {
  color: #F55143;

}

.textShadow {
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}
