.walletButton {
  width: 404px;
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #bababa;
}
/* targets the text of the button */
.walletButton div {
  text-align: start;
  padding-left: 15px;
}

/* targets the image of the button */
.walletButton > img {
  padding-left: 5px;
}
@media screen and (max-width: 650px) {
  .walletButton {
    flex-direction: column;
    width: 110px;
    height: 130px;

    align-content: center;
    justify-content: space-evenly;
  }
  .walletButton div {
    padding: 0;
    width: 110px;
    text-align: center;
  }
  .walletButton > img {
    padding-left: 0;
  }
}
