.profileModalCardContainer {
  font-family: Roboto Condensed;
  width: 100%;
  height: 100%;
  background-color: #00000050;
  position: fixed;
  top: 0;
  left: 0;
  z-index:51;
}

.profileModalCardDiv {
  border: lightblue solid 1px;
  box-shadow: 2px 2px grey;
  z-index: 52;
  min-height: 180px;
  height: fit-content;
  min-width: 270px;
  width: 85%;
  max-width: 370px;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 16px;
  background-color: white;
  position: fixed;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  color: blue;
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0;
  margin: auto;
  font-size: 20px;
}

.profileModalCardInnerDiv {
  display: flex;
  width: 100%;

  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.profileModalCardxBtn {
  border: none;
  background: none;
  align-self: flex-end;
  margin-right: 20px;
  margin-top: 10px;
}

.profileModalCardxBtn:hover {
  opacity: 0.8;
}

.profileModalCardxImg {
  width: 15px;
  height: 15px;
}

@media screen and (max-width: 650px) {
  .profileModalCardDiv img {
    transform: scale(0.8);
  }
  .profileModalCardDiv .languageSelectorContainer {
    transform: scale(0.9);
  }
}
