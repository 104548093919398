.modalWith1ButtonContainer {
  width: 100%;
  height: 100%;

  background-color: #00000050;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 51;
}

.modalWith1ButtonDiv {
  border: lightblue solid 1px;
  box-shadow: 2px 2px grey;
  z-index: 8;
  height: 30vh;
  min-height: 180px;
  max-height: 400px;

  max-width: 370px;
  min-width: 300px;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 20px;
  background-color: white;
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: blue;
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0;
  margin: auto;
  font-size: 20px;
}

.modalWith1ButtonInnerDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70%;
  margin-left: 1rem;
  margin-right: 1rem;
}

.modalWith1ButtonP {
  padding: 3%;
}

.modalWith1ButtonText {
  font-family: "Roboto Condensed";
  font-size: 20px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.modalWith1ButtonButtonWrap {
  margin-top: -10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.leftButtonClose:hover {
  opacity: 0.5;
}

.rightButtonClose {
  font-size: 16px;
  font-weight: 600;
  color: blue;
  border-radius: 0 0 20px 0px;
  background-color: #e1e1df;
  width: 50%;
  height: 50px;
  background-color: #e1e1df;
  cursor: pointer;
}

.rightButtonClose:hover {
  opacity: 0.5;
}

.modal-button {
  width: 165px;
  height: 60px;
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
  border: none;
  border-radius: 7px 14px;
  transform: skew(-19deg);
  /*/ usefull for centering text-section /*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-button > div,
.modal-button-red > div,
.modal-button-small > div,
.modal-button-small-red > div {
  transform: skew(19deg);
}

.modal-button > div {
  transform: skew(19deg);
}

.modalButtonBlue {
  width: 165px;
  height: 60px;
  background: linear-gradient(180deg, #00b4ff 0%, #00b4ff 100%);
  border: none;
  border-radius: 7px 14px;
  transform: skew(-19deg);
  /*/ usefull for centering text-section /*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-button-red {
  width: 165px;
  height: 60px;

  border: none;
  border-radius: 7px 14px;
  transform: skew(-19deg);
  /*/ usefull for centering text-section /*/
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(130.79deg, #f55d5d 24.71%, #dd3131 86.92%);
}

.modal-button-small-red {
  width: 95px;
  height: 60px;

  border: none;
  border-radius: 7px 14px;
  transform: skew(-19deg);
  /*/ usefull for centering text-section /*/
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(130.79deg, #f55d5d 24.71%, #dd3131 86.92%);
}
.modal-button-small {
  width: 95px;
  height: 60px;
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
  border: none;
  border-radius: 7px 14px;
  transform: skew(-19deg);
  /*/ usefull for centering text-section /*/
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .modalWith1ButtonDiv {
    width: 75%;
  }
}
