.gameMode-slider {
  display: flex;
  border-radius: 50px;
  border: 1px solid #bababa;
  background: rgba(0, 0, 0, 0.1);
  padding: 3px;
  /* justify-content: space-around; */
  /* width: 750px; */
  min-height: 70px;
}

.button {
  position: relative;
  flex: content;
  border: none;
  background: none;
  min-width: 160px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  border-radius: 50px;
  --background-gradient: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
}

.active {
  background: var(--background-gradient);
}

.grey {
  color: #717380;
}

.messageNotification{
  background-color: red;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top:20px;
  right:50px;
}

@media screen and (max-width: 700px) {
  .gameMode-slider {
    min-height: 34px;
  }

  .button {
    min-width: 74px;
    padding: 0 10px;
    display: flex;
    height: 34px;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    border-radius: 50px;
    --background-gradient: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
  }

  .messageNotification{
    background-color: red;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top:8px;
    right:8px;
  }
}
