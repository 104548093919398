.formContainer {
  margin: 0 auto;
  padding-top: clamp(0px, 10px + (1000px - 100vh) * 1, 900px);
  width: clamp(
    343px,
    calc(343px + (432 - 343) * ((100vw - 400px) / (660 - 400))),
    432px
  );
}

.formContainer h2 {
  text-align: center;
  margin: 12px 0 35px 0;
}

.formContainer h2 > div {
  font-size: clamp(
    32px,
    calc(32px + (40 - 32) * ((100vw - 400px) / (660 - 400))),
    40px
  );
}

.formInputsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputContainer {
  position: relative;
}

.inputContainer input {
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  border-radius: 16px;
  padding: 0 20px 0 48px;
  border: 1px solid #bababa;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  font-family: Gilroy;
  font-weight: 400;
}

.checkboxInput > input {
  appearance: none;
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 8px;
}

.checkboxInput > input:checked {
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: rgb(255, 200, 0);
}

.labelInputWrapper {
  display: flex;
  align-items: center;
}

.inputContainer img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 16px;
}

.errorMessage {
  color: red;
  position: absolute;
}

.linkButton {
  border: none;
  background: none;
  color: #257cff;
  font-size:16px;
}

.createSection {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.linkArea2 > button {
  margin: 30px auto 0;
  width: 95%;
  height: clamp(
    60px,
    calc(60px + (70 - 60) * ((100vw - 400px) / (660 - 400))),
    70px
  );
}

.linkArea2 > button div {
  font-size: clamp(
    24px,
    calc(24px + (30 - 24) * ((100vw - 400px) / (660 - 400))),
    30px
  );
}

.linkArea2 :nth-child(2) {
  margin-top: 16px;
  text-align: center;
}

.loginIconsContainer {
  border: solid;
  padding: clamp(
      9px,
      calc(9px + (14 - 9) * ((100vw - 400px) / (660 - 400))),
      14px
    )
    0;
}

.avatarInput {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 48px;
  padding-top: 50px;
}

.avatarInput > button {
  height: clamp(
    60px,
    calc(60px + (70 - 60) * ((100vw - 400px) / (660 - 400))),
    70px
  );
  width: 95%;
}

.avatarInput > button div {
  font-size: clamp(
    24px,
    calc(24px + (30 - 24) * ((100vw - 400px) / (660 - 400))),
    30px
  );
}

.avatarTitle {
  margin: -100px;
}

@media screen and (max-width: 650px) {
  .avatarInput {
    margin-top: 0px;
  }
  .avatarInput > button {
    margin-top: 20px;
  }

  .inputWrapper2 {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    gap: 20px;
  }
}
