.bonusIcon {
    position: absolute;
    bottom: 60px;
    right:15px;
}

@media screen and (max-width: 768px) {

    .bonusIcon {
        width: 30%;
        bottom: 47px;
        left:65%;
    }

}