.font24 {
  font-size: 24px;
}

.font36 {
  font-size: 36px;
}

.itemsInfo {
  display: flex;
  flex-direction: column;
  width: 432px;
  gap: 16px;
  justify-content: space-between;
}

.itemBox,
.ticketBox {
  display: flex;
  justify-content: space-between;
}

.itemBox span {
  display: flex;
  align-items: flex-end;
  padding: 15px;
  gap: 10px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #ffce0d;
}

.yellowButtonsSection{
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin: 0 auto;
gap: 10px;
}

.yellowButtonsSection > button {
  border: none;
  width:100%;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  height: 54px;
  border-radius: 16px;
  background: linear-gradient(180deg, #ffce0d 0%, #ffaf06 100%);
}

@media screen and (max-width: 1050px) {
  .itemBox span{
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 768px) {
  .itemsInfo {
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .itemBox span{
    margin-bottom: 70px;
  }
  .yellowButtonsSection > button {
    align-self: center;
    width: 96%;
    max-width: 400px;
  }
}
